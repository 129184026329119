import { collection, doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { getAllUserList } from "../../common/fetch";
import { db } from "../../common/firebase";
import AdminHeader from "../../components/adminHeader/AdminHeader";
import AdimnSidebar from "../../components/adminSidebar/AdminSidebar";
import { SuccessBadge, WarningBadge } from "../../components/badges/Badges";
import { DATABASECOLLECTION } from "../../constants/DatabaseConst";
import { ROLES } from "../../constants/RoleConst";
import { ADMIN_ROUTES } from "../../constants/RouteConst";
import Loader from "../extra/Loader";

const UserListing = () => {
  const [data, setData] = useState();
  const [finalData, setFinalData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    role: "",
  });

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const usersCollection = collection(db, DATABASECOLLECTION.USERPROFILES);
        const userListing = await getAllUserList();
        // console.log({userListing})

        const promises = userListing.map(async (user) => {
          const userDocRef = doc(usersCollection, user.uid);
          const userDocumentSnapshot = await getDoc(userDocRef);
          if (userDocumentSnapshot.exists()) {
            const userDetails = userDocumentSnapshot.data();
            const stateRef = collection(userDocRef, DATABASECOLLECTION.STATE);
            const currentDocRef = doc(stateRef, DATABASECOLLECTION.CURRENT);
            const currentDocSnap = await getDoc(currentDocRef);
            userDetails.creationTime = user.metadata.creationTime;
            userDetails.role = user.customClaims.role;
            userDetails.uid = user.uid;
            userDetails.state = currentDocSnap.data();
            return userDetails;
          }
          return null;
        });

        const resolvedPromises = await Promise.all(promises);
        const userList = resolvedPromises.filter((user) => user !== null);

        setData(userList);
        setFinalData(userList);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching documents:", error.message);
      }
    };

    fetchData();
  }, []);

  const resetFilters = () => {
    setFilters({
      name: "",
      email: "",
      role: "",
    });
    setData(finalData);
  };

  useEffect(() => {
    const filterData = async () => {
      if (!finalData) return;

      try {
        let filteredData = [...finalData];

        // Apply name filter
        if (filters.name.trim()) {
          filteredData = filteredData.filter((user) => {
            const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
            return fullName.includes(filters.name.toLowerCase());
          });
        }

        // Apply email filter
        if (filters.email.trim()) {
          filteredData = filteredData.filter((user) =>
            user.emailAddress
              ?.toLowerCase()
              .includes(filters.email.toLowerCase())
          );
        }

        // Apply role filter
        if (filters.role) {
          filteredData = filteredData.filter(
            (user) => user.role.toLowerCase() === filters.role.toLowerCase()
          );
        }

        setData(filteredData);
      } catch (error) {
        console.error("Error filtering data:", error);
      }
    };

    filterData();
  }, [filters, finalData]);

  const columns = [
    {
      name: "Full Name",
      selector: (row) => row.firstName + " " + row.lastName,
      sortable: true,
      filter: true,
    },
    {
      name: "Email ID",
      selector: (row) => row.emailAddress,
      sortable: true,
      filter: true,
    },
    {
      name: "Role",
      selector: (row) => row.role.charAt(0).toUpperCase() + row.role.slice(1),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.role === ROLES.CLINICIANINCLINIC ||
        row.role === ROLES.CLINICIANINHOUSE ||
        row.role === ROLES.CLINICIANPENDING
          ? row.role === ROLES.CLINICIANINCLINIC ||
            row.role === ROLES.CLINICIANINHOUSE
            ? "Activated"
            : "Pending"
          : "N/A",
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const statusA =
          rowA.role === ROLES.CLINICIANINCLINIC ||
          rowA.role === ROLES.CLINICIANINHOUSE ||
          rowA.role === ROLES.CLINICIANPENDING
            ? rowA.role === ROLES.CLINICIANINCLINIC ||
              rowA.role === ROLES.CLINICIANINHOUSE
              ? "Activated"
              : "Pending"
            : "N/A";
        const statusB =
          rowB.role === ROLES.CLINICIANINCLINIC ||
          rowB.role === ROLES.CLINICIANINHOUSE ||
          rowB.role === ROLES.CLINICIANPENDING
            ? rowB.role === ROLES.CLINICIANINCLINIC ||
              rowB.role === ROLES.CLINICIANINHOUSE
              ? "Activated"
              : "Pending"
            : "N/A";

        return statusA.localeCompare(statusB);
      },
      cell: (row) =>
        row.role === ROLES.CLINICIANINCLINIC ||
        row.role === ROLES.CLINICIANINHOUSE ||
        row.role === ROLES.CLINICIANPENDING ? (
          row.role === ROLES.CLINICIANINCLINIC ||
          row.role === ROLES.CLINICIANINHOUSE ? (
            <SuccessBadge text="Activated" />
          ) : (
            <WarningBadge text="Pending" />
          )
        ) : (
          "N/A"
        ),
    },
    {
      name: "UID",
      selector: (row) => row.uid,
      sortable: true,
      filter: true,
    },
    {
      name: "Created At",
      selector: (row) => row.creationTime,
      sortable: true,
      filter: true,
    },
    {
      name: "Actions",
      cell: (row) => <CustomActionsCell row={row} />,
      ignoreRowClick: true,
      // allowOverflow: true,
      button: true,
    },
  ];

  const CustomActionsCell = ({ row }) => {
    const patient = row;
    const updateURL = `${ADMIN_ROUTES.EDIT_USER}/${patient.uid}`;
    return (
      <div className="col-auto">
        <Link to={updateURL} className="text-decoration-none">
          <button type="button" className="editBtn btn btn-outline-dark">
            <div className="editPencilImg d-flex align-items-center gap-1">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                style={{ height: "14px", width: "14px" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M464 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zM128 120c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm288-136v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12z"></path>
              </svg>
              Details
            </div>
          </button>
        </Link>
      </div>
    );
  };

  return (
    <>
      <div className="dashboardWrapper">
        <AdimnSidebar />
        <div className="adminDashBoard">
          <AdminHeader />
          <div className="adminBody">
            <div className="row">
              <div className="col-12">
                <div className="commonCard">
                  <div className="card-header">
                    <div className="col-12">
                      <div className="row align-items-center">
                        <div className="col">
                          <h4 className="SectionHeadingMain">Manage Users</h4>
                        </div>
                        <div className="col-auto">
                          <Link
                            to={`${ADMIN_ROUTES.ADD_ADMIN}`}
                            className="text-decoration-none"
                          >
                            <button className="editBtn btn btn-dark text-white">
                              <div className="editPencilImg d-flex align-items-center gap-1">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 448 512"
                                  style={{ width: "14px", height: "14px" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path>
                                </svg>{" "}
                                Add Admin
                              </div>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {isLoading && <Loader />}
                    {!isLoading && (
                      <>
                        <div className="table-filters mb-3">
                          <div className="row g-2 align-items-center">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control form-control-sm filter-input"
                                placeholder="Filter by name"
                                value={filters.name}
                                onChange={(e) =>
                                  setFilters((prev) => ({
                                    ...prev,
                                    name: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            <div className="col">
                              <input
                                type="text"
                                className="form-control form-control-sm filter-input"
                                placeholder="Filter by email"
                                value={filters.email}
                                onChange={(e) =>
                                  setFilters((prev) => ({
                                    ...prev,
                                    email: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            <div className="col">
                              <select
                                className="form-select form-select-sm filter-input"
                                value={filters.role}
                                onChange={(e) =>
                                  setFilters((prev) => ({
                                    ...prev,
                                    role: e.target.value,
                                  }))
                                }
                              >
                                <option value="">All Roles</option>
                                <option value={ROLES.CLINICIANPENDING}>
                                  {ROLES.CLINICIANPENDING.charAt(
                                    0
                                  ).toUpperCase() +
                                    ROLES.CLINICIANPENDING.slice(1)}
                                </option>
                                <option value={ROLES.CLINICIANINHOUSE}>
                                  {ROLES.CLINICIANINHOUSE.charAt(
                                    0
                                  ).toUpperCase() +
                                    ROLES.CLINICIANINHOUSE.slice(1)}
                                </option>
                                <option value={ROLES.CLINICIANINCLINIC}>
                                  {ROLES.CLINICIANINCLINIC.charAt(
                                    0
                                  ).toUpperCase() +
                                    ROLES.CLINICIANINCLINIC.slice(1)}
                                </option>
                                <option value={ROLES.ADMIN}>
                                  {ROLES.ADMIN.charAt(0).toUpperCase() +
                                    ROLES.ADMIN.slice(1)}
                                </option>
                                <option value={ROLES.CLIENT}>
                                  {ROLES.CLIENT.charAt(0).toUpperCase() +
                                    ROLES.CLIENT.slice(1)}
                                </option>
                              </select>
                            </div>
                            <div className="col-auto">
                              <button
                                className="btn btn-outline-secondary btn-sm filter-reset-btn"
                                onClick={resetFilters}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="managepatientsTable">
                          {data.length === 0 ? (
                            <>
                              <div className="cfvlby">
                                {columns.map((column, index) => (
                                  <div
                                    className="sc-crrtmM WubHI"
                                    style={{ paddingLeft: "36px" }}
                                  >
                                    <span
                                      key={index}
                                      className="sc-dQpIV ofEne"
                                    >
                                      {column.name}
                                    </span>
                                  </div>
                                ))}
                              </div>
                              <p className="py-2 text-center">
                                There are no records to display
                              </p>
                            </>
                          ) : (
                            <DataTable
                              fixedHeader
                              fixedHeaderScrollHeight="700px"
                              highlightOnHover
                              noContextMenu
                              pagination
                              responsive
                              subHeaderAlign="right"
                              subHeaderWrap
                              columns={columns}
                              data={data}
                              theme="default"
                              customStyles={{
                                table: {
                                  style: {
                                    backgroundColor: "transparent",
                                  },
                                },
                                rows: {
                                  style: {
                                    minHeight: "50px",
                                  },
                                },
                              }}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserListing;
