import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/frontendComponents/header/Header";
import { FILENAME, STORAGE, STORAGEURL } from "../../../constants/StorageConst";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../common/firebase";
import { Link, useNavigate, useParams } from "react-router-dom";
import he from "he";
import { FRONTEND_ROUTES } from "../../../constants/RouteConst";
import Loader from "../../loader/Loader";
import Footer from "../../../components/frontendComponents/footer/Footer";

const BlogDetail = () => {
  const scrollContainer = useRef(null);
  const slug = useParams();
  const [jsonData, setJsonData] = useState(null);
  const [bodyData, setBodyData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigator = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const jsonFileBlogRef = ref(
        storage,
        STORAGEURL + "/" + STORAGE.BLOGS + "/" + FILENAME.BLOGFILENAME
      );
      const jsonFileTestiRef = ref(
        storage,
        STORAGEURL + "/" + STORAGE.BLOGS + "/" + FILENAME.TESTIMONIALSFILE
      );
      try {
        const urlBlog = await getDownloadURL(jsonFileBlogRef);
        const urlTesti = await getDownloadURL(jsonFileTestiRef);
        const responseBlog = await fetch(urlBlog);
        const responseTesti = await fetch(urlTesti);
        const dataBlog = await responseBlog.json();
        const dataTesti = await responseTesti.json();
        const mergedData = [...dataBlog, ...dataTesti];
        const indexOfObject = mergedData.findIndex(
          (item) => item.slug === slug.slug
        );

        if (indexOfObject !== -1) {
          const targetObject = mergedData[indexOfObject];
          setJsonData(targetObject);
          const htmlBody = ref(
            storage,
            STORAGEURL + "/" + STORAGE.BLOGS + "/" + targetObject.body
          );
          const bodyContent = await getDownloadURL(htmlBody);
          const responseBody = await fetch(bodyContent);
          const htmlContent = await responseBody.text();
          const decodedData = he.decode(htmlContent);
          setBodyData(decodedData);
        } else {
          navigator("/404");
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching or parsing JSON file:", error);
      }
    };
    fetchData();
  }, [navigator, slug.slug]);

  return (
    <>
      <div className="frontendOuter paddingTopHeader" ref={scrollContainer}>
        <Header scrollContainer={scrollContainer} secondHeader={true} />
        {isLoading && <Loader />}
        {!isLoading && jsonData && (
          <div className="py-8 bg-gray-100">
            <div className="max-w-4xl mx-auto px-6 md:px-8">
              {/* Title Section */}
              <div className="text-center mb-8">
                <h1 className="text-4xl font-semibold text-gray-800">
                  {jsonData.title}
                  {jsonData?.sub_title && (
                    <span className="text-gray-600">
                      {" "}
                      - {jsonData.sub_title}
                    </span>
                  )}
                </h1>
                <p className="text-gray-500 mt-2">
                  <span className="font-medium">Author:</span>{" "}
                  {jsonData.author ?? jsonData.name}
                </p>
                <p className="text-gray-500">
                  <span className="font-medium">Published:</span>{" "}
                  {jsonData.date ?? "N/A"}
                </p>
              </div>

              {/* Content Section */}
              <div className="prose prose-lg max-w-none bg-white p-6 rounded-lg shadow">
                {bodyData && (
                  <div dangerouslySetInnerHTML={{ __html: bodyData }}></div>
                )}
              </div>

              {/* Back Link */}
              <div className="mt-6">
                <Link
                  to={FRONTEND_ROUTES.BLOGS}
                  className="flex items-center text-primary font-medium hover:underline"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 19.5L3.75 12.75M3.75 12.75L10.5 6M3.75 12.75H20.25"
                    />
                  </svg>
                  Back to Blogs
                </Link>
              </div>
            </div>
          </div>
        )}
        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default BlogDetail;
