import { getISOWeek } from "date-fns";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { ModalBody, ModalHeader } from "react-bootstrap";
import ReactModal from "react-modal";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getClinicanList, getData, sendEmail } from "../../common/fetch";
import { db } from "../../common/firebase";
import {
  checkEmailExists,
  createEvent,
  firestoreTransaction,
  getTemplateContent,
} from "../../common/helper";
import RangeSlider from "../../common/slider";
import AdminHeader from "../../components/adminHeader/AdminHeader";
import AdimnSidebar from "../../components/adminSidebar/AdminSidebar";
import EventGraph from "../../components/chart/EventGraph.jsx";
import { DATABASECOLLECTION } from "../../constants/DatabaseConst";
import { EventType } from "../../constants/EventConst";
import { ROLES } from "../../constants/RoleConst";
import { TEMPPLATETYPE } from "../../constants/TemplateTypeConst.js";
import Loader from "../extra/Loader";
import TRQModal from "./TRQModal";

const ManageClient = () => {
  const emptyTRQ = {
    500: 0,
    750: 0,
    1000: 0,
    2000: 0,
    3000: 0,
    4000: 0,
    6000: 0,
    8000: 0,
    10000: 0,
    12500: 0,
  };
  const uid = useParams();
  const [data, setData] = useState("");
  const [clientID] = useState(uid.id);
  const [currentTinnitusCharactersation, setCurrentTinnitusCharactersation] =
    useState("");
  const [currentTRQ, setCurrentTRQ] = useState("");
  const [currentLeftAG, setCurrentLeftAG] = useState("");
  const [currentRightAG, setCurrentRightAG] = useState("");
  // const [currentModTRQ, setCurrentCustomTRQ] = useState("");
  const [currentCustomLeftAG, setCurrentCustomLeftAG] = useState(emptyTRQ);
  const [currentCustomRightAG, setCurrentCustomRightAG] = useState(emptyTRQ);
  const [treatmentSettings, setTreatmentSettings] = useState("");
  const [events, setEvents] = useState("");
  const [clinicianList, setClinicianList] = useState("");
  const [selectedClinician, setSelectedClinician] = useState("");
  const [enteredClinician, setEnteredClinician] = useState("");
  const [selectedAudioGram, setSelectedAudiogram] = useState("");
  const [phase, setPhase] = useState();
  const [balance, setBalance] = useState();
  const [neuralStimulus, setNeuralStimulus] = useState();
  const [TRQData, setTRQData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [canSaveCustomAudioGram, setCanSaveCustomAudioGram] = useState(false);
  const role = localStorage.getItem("role");
  // const role = ROLES.ADMIN;
  const [userClinician, setUserClinician] = useState({});
  const [query, setQuery] = useSearchParams();
  // console.log({userClinician})
  // const checkEmailExists = async (email) => {
  //     try {
  //         const snapshot = await userProfilesRef.where('email', '==', email).get();
  //         return !snapshot.empty; // If not empty, email exists
  //     } catch (error) {
  //         console.error('Error checking email existence:', error);
  //         return false;
  //     }
  // };
  // console.log({ clinicianList, selectedClinician })

  useEffect(() => {
    if (data?.clinicianUID && clinicianList) {
      const arr = data?.clinicianUID?.split("/");
      // console.log("clinicianList",arr?.[1],clinicianList)
      const clinician = clinicianList?.filter(
        (item) => item?.uid === arr?.[1]?.trim()
      );
      // console.log("cliniciannn", { clinician, arr, clinicianList })
      setUserClinician(clinician?.[0] ? clinician?.[0] : {});
    }
  }, [clinicianList, data?.clinicianUID]);

  /* TRQ Modal */
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  /* TRQ Modal */

  /* Set value for treatment Setting */
  const handleBalanceChange = (newValue) => {
    setBalance(newValue);
  };

  const handleNeuralStimulusChange = (newValue) => {
    setNeuralStimulus(newValue);
  };
  /* Set value for treatment Setting */
  const getPatientDetail = useCallback(async () => {
    const usersCollection = collection(db, DATABASECOLLECTION.USERPROFILES);
    const userDocRef = doc(usersCollection, clientID);
    const userDocumentSnapshot = await getDoc(userDocRef);
    // console.log({userDocumentSnapshot:userDocumentSnapshot.data()})
    if (userDocumentSnapshot.data()) {
      const userDetails = userDocumentSnapshot.data();
      if (userDetails.currentTRQ) {
        const trqResponse = await getDoc(userDetails.currentTRQ);
        const trqData = trqResponse.data();
        const trqResponseData = trqData.responses.slice(0, -2);
        setTRQData(trqData.responses);
        const finalTRQ = trqResponseData.reduce(
          (total, item) => total + (item.value || 0),
          0
        );
        setCurrentTRQ(finalTRQ);
      }
      if (userDetails.currentTinnitusCharactersation) {
        const tinnitusResponse = await getDoc(
          userDetails.currentTinnitusCharactersation
        );
        const tinnitusData = tinnitusResponse.data();
        const tinnitusResponseData = tinnitusData.responses;
        setCurrentTinnitusCharactersation(tinnitusResponseData);
      }
      const userSettingsRef = collection(
        db,
        `${DATABASECOLLECTION.USERPROFILES}/${clientID}/otherSettings`
      );
      const userSettingsSnapshot = await getDocs(userSettingsRef);
      if (!userSettingsSnapshot.empty) {
        const clientUIDsReferences = userSettingsSnapshot.docs[0];
        const clientUIDsDataU = clientUIDsReferences.data() || [];
        userDetails.clinicianUID = clientUIDsDataU.clinicianUID.path;
        setSelectedClinician(clientUIDsDataU.clinicianUID.path);
        const getClinicianEmailID = await getDoc(clientUIDsDataU?.clinicianUID);
        // console.log({ getClinicianEmailID: getClinicianEmailID.data()?.emailAddress, userDetails: userDetails.clinicianUID?.trim(), path: clientUIDsDataU.clinicianUID.path })
        setEnteredClinician(getClinicianEmailID.data()?.emailAddress);
      }
      setData(userDetails);
    }
  }, [clientID]);
  useEffect(() => {
    setIsLoading(true);

    const getAudiogram = async () => {
      const audiosCollection = collection(db, DATABASECOLLECTION.USERAUDIO);
      const audioDocRef = doc(audiosCollection, clientID);
      const audioDocumentSnapshot = await getDoc(audioDocRef);
      if (audioDocumentSnapshot.data()) {
        const audioDetails = audioDocumentSnapshot.data();
        // if (audioDetails.isUsingCustomAudiogram ==  true) {
        //   getCustomAudiogam(audioDetails)
        // }
        getCustomAudiogam(audioDetails)
        const audioGram = audioDetails.audiogram;
        setCurrentLeftAG(audioGram.left);
        setCurrentRightAG(audioGram.right);
        setTreatmentSettings(audioDetails);
        setPhase(audioDetails.phase ?? 0);
        setBalance(audioDetails.balance ?? 0);
        setNeuralStimulus(audioDetails.neuralStimulus ?? 0);
      }
    };
    const getCustomAudiogam = async (audioDetails) => {
      if ( audioDetails.currentCustomAudiogram ) {
        const customAudiogram = await getDoc(
          audioDetails.currentCustomAudiogram
        );
        let custAGData = await customAudiogram.data()
        setCurrentCustomLeftAG(custAGData.audiogram.left);
        setCurrentCustomRightAG(custAGData.audiogram.right);
        if (audioDetails.isUsingCustomAudiogram ==  true) {
          setSelectedAudiogram("Custom");
        }
        setCanSaveCustomAudioGram(false);  
      }
    };
    const getEvents = async () => {
      const eventCollection = collection(
        db,
        `${DATABASECOLLECTION.USEREVENTS}/${clientID}/events`
      );
      const querySnapshot = await getDocs(eventCollection);
      if (querySnapshot.empty) {
        console.log("No documents found in userSettings");
      }
      const resultEvent = [];
      for (const docn of querySnapshot.docs) {
        const eventDocs = {
          id: docn.id,
          ...docn.data(),
        };
        // Add a condition to include only items with type "usage"
        // if (eventDocs.type === 'usage') {
        resultEvent.push(eventDocs);
        // }
      }
      let resultEventSorted = resultEvent.sort(
        (a, b) => a?.date?.seconds - b?.date?.seconds
      );
      console.debug("RESULT EVENT SORT");
      console.debug(resultEventSorted);
      function segregateByWeek(data) {
        const result = [];
        data.forEach((item) => {
          console.debug("RESULT ITEM")
          console.debug(item);
          const timestamp =
            item.date.seconds * 1000 + item.date.nanoseconds / 1e6;
          const date = new Date(timestamp);
          const dateString = new Date(
            item.date.seconds * 1000
          ).toLocaleDateString("en-US", {
            weekday: "short",
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          const isoWeek = getISOWeek(date);
          const isoWeekKey = `${date.getFullYear()}-W${String(isoWeek).padStart(
            2,
            "0"
          )}`;

          if (!result[isoWeekKey]) {
            result[isoWeekKey] = [item];
          } else {
            result[isoWeekKey].push(item);
          }
          result[isoWeekKey]["dateString"] = dateString;
        });
        return result;
      }

      setEvents(
        segregateByWeek(
          //resultEvent.sort((a, b) => a?.date?.seconds - b?.date?.seconds)
          resultEventSorted
        )
      );
      setIsLoading(false);
    };

    getPatientDetail(); //get all the patients details
    getAudiogram(); //get patient audioGram data
    //getCustomAudiogam(); //get patient customAudiogram data
    getEvents(); //get all patient events
  }, [clientID, getPatientDetail]);

  useEffect(() => {
    const getclinicianList = async () => {
      try {
        const usersCollection = collection(db, DATABASECOLLECTION.USERPROFILES);
        console.log("users ------------> before");

        // Get initial users list
        const users = await getClinicanList();
        console.log("users ------------> after", users);

        // Use Promise.all to fetch all user details in parallel
        const clinicianL = await Promise.all(
          users.map(async (user) => {
            const userDocRef = doc(usersCollection, user.uid);
            const [userDocumentSnapshot, stateSnapshot] = await Promise.all([
              getDoc(userDocRef),
              getDoc(
                doc(
                  collection(userDocRef, DATABASECOLLECTION.STATE),
                  DATABASECOLLECTION.CURRENT
                )
              ),
            ]);

            if (userDocumentSnapshot.exists()) {
              const userDetails = userDocumentSnapshot.data();
              userDetails.creationTime = user.metadata.creationTime;
              userDetails.uid = user.uid;
              userDetails.state = stateSnapshot.exists()
                ? stateSnapshot.data()
                : null;
              return userDetails;
            }
            return null;
          })
        );

        // Filter out any null values and sort
        const sorted = clinicianL
          .filter(Boolean)
          .sort((a, b) =>
            `${a?.firstName || ""} ${a?.lastName || ""}`.localeCompare(
              `${b?.firstName || ""} ${b?.lastName || ""}`
            )
          );

        console.log("Sorted: ----------------> ", sorted);
        setClinicianList(sorted);
      } catch (error) {
        console.error("Error fetching clinician list:", error);
        // Handle error appropriately
      }
    };

    getclinicianList();
  }, []);

  const handleAssign = async (e) => {
    // Assign clinician after confirmation
    const isClinician = role && ROLES.CLINICIAN === role;
    const clinicianEmail = isClinician ? enteredClinician : selectedClinician;

    if (clinicianEmail.trim() === "") {
      Swal.fire(
        "Error",
        "The clinician email field must not be left blank",
        "error"
      );
      return false;
    }

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to assign a clinician to this client !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, assign it!",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    });
    if (result.isConfirmed) {
      try {
        /* For Clinicians portal, had option to enter only email not able to select from dropdown */
        if (role && ROLES.CLINICIAN === role) {
          const checkEnteredEmailExists = await getData(enteredClinician);
          if (
            checkEnteredEmailExists !== undefined &&
            checkEnteredEmailExists?.customClaims?.role === ROLES.CLINICIAN
          ) {
            setSelectedClinician(checkEnteredEmailExists.uid);
          }
        }
        /* end */
        const userProfileDocRef = doc(
          collection(db, DATABASECOLLECTION.USERPROFILES),
          clientID
        );

        const otherSettingsCollectionRef = collection(
          userProfileDocRef,
          DATABASECOLLECTION.OTHERSETTINGS
        );
        const clinicianIDUpdateDocRef = doc(
          otherSettingsCollectionRef,
          DATABASECOLLECTION.ACTIVATIONCODE
        );
        const activationCodeGet = await getDoc(clinicianIDUpdateDocRef);
        const clinicianUIDReference = doc(db, `${selectedClinician}`);
        if (activationCodeGet.data()) {
          const activationCodeGetData = activationCodeGet.data();
          const previousClinicianUID =
            activationCodeGetData.clinicianUID.path?.trim();
          const [previousClinicianCollection, previousClinicianDocID] =
            previousClinicianUID.split("/");
          const previousClinicianDocRef = doc(
            collection(db, previousClinicianCollection),
            previousClinicianDocID?.trim()
          );
          const previousClinicianCollectionRef = collection(
            previousClinicianDocRef,
            DATABASECOLLECTION.OTHERSETTINGS
          );
          const previousClinicianUpdateDocRef = doc(
            previousClinicianCollectionRef,
            "clientUID"
          );
          const previousClinicianGet = await getDoc(
            previousClinicianUpdateDocRef
          );
          const previousClinicianArr = previousClinicianGet?.data()?.clientUIDs;
          // let needToUpdateIndex = -1;
          // for (const [index, prevClc] of previousClinicianArr.entries()) {
          //     if (`userProfiles/${clientID}` === prevClc.path) {
          //         needToUpdateIndex = index;
          //         break;
          //     }
          // }
          // if (needToUpdateIndex !== -1) {
          //     previousClinicianArr[needToUpdateIndex] = doc(db,selectedClinician);
          //     console.log(needToUpdateIndex, previousClinicianArr, selectedClinician);
          //     // await updateDoc(previousClinicianDocRef, { clientUIDs: previousClinicianArr });
          // }

          const updatedArray = previousClinicianArr.filter(
            (prevClc) =>
              `${DATABASECOLLECTION.USERPROFILES}/${clientID}` !== prevClc.path
          );
          await updateDoc(previousClinicianUpdateDocRef, {
            clientUIDs: updatedArray,
          }); // update client UID from previous Clinician

          // let needToUpdateIndex = -1;
          // for (const [index, prevClc] of previousClinicianArr.entries()) {
          //     if (`userProfiles/${clientID}` === prevClc.path) {
          //         needToUpdateIndex = index;
          //         break;
          //     }
          // }
          // if (needToUpdateIndex !== -1) {
          //     previousClinicianArr[needToUpdateIndex] = doc(db,selectedClinician);
          //     await updateDoc(previousClinicianDocRef, { clientUIDs: previousClinicianArr });
          // }

          const [selectedClinicianCollection, selectedClinicianDocID] =
            selectedClinician?.trim().split("/");
          const userProfileClinicianDocRef = doc(
            collection(db, selectedClinicianCollection),
            selectedClinicianDocID.trim()
          );
          const otherSettingsClinicianCollectionRef = collection(
            userProfileClinicianDocRef,
            DATABASECOLLECTION.OTHERSETTINGS
          );
          const clientUIDDocRef = doc(
            otherSettingsClinicianCollectionRef,
            "clientUID"
          );
          const clientUIDDocSnapshot = await getDoc(clientUIDDocRef);
          if (clientUIDDocSnapshot.exists()) {
            await setDoc(
              clientUIDDocRef,
              {
                clientUIDs: arrayUnion(
                  doc(db, `${DATABASECOLLECTION.USERPROFILES}/${clientID}`)
                ), // if key already exists, update client UID to new selected  Clinician
              },
              { merge: true }
            );
          } else {
            await setDoc(clientUIDDocRef, {
              clientUIDs: [
                doc(db, `${DATABASECOLLECTION.USERPROFILES}/${clientID}`),
              ], // if key not exists, update client UID from previous Clinician
            });
          }
          await updateDoc(clinicianIDUpdateDocRef, {
            clinicianUID: clinicianUIDReference, // update clinician UID in client profile
          });
        }
        Swal.fire("Assigned!", "Clinician has been assigned.", "success");
      } catch (error) {
        Swal.fire("Error!", "Something went wrong! Please try again.", "error");
      }
    }
  };

  const handleReAssign = async (e) => {
    // Assign clinician after confirmation
    const clinicianEmail = enteredClinician;

    if (clinicianEmail.trim() === "") {
      Swal.fire(
        "Error",
        "The clinician email field must not be left blank",
        "error"
      );
      return false;
    }

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to re-assign a clinician to this client !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Re-assign!",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    });

    const emailExists = await checkEmailExists(clinicianEmail);

    const clinicianRes = await getData(clinicianEmail);

    if (
      result.isConfirmed &&
      emailExists &&
      clinicianRes?.customClaims?.role?.includes("clinician")
    ) {
      try {
        //send Email to the clinician about re assignment
        let clinicianData = {};
        // if (role == ROLES.ADMIN) {
        // if (selectedClinician && clinicianList) {
        // const arr = selectedClinician?.trim()?.split('/')
        // const clinician = clinicianList?.filter(item => item?.uid == arr?.[1])
        // clinicianData = { ...clinician?.[0] }
        // }
        // } else {
        if (data?.clinicianUID && clinicianList) {
          const clinician = clinicianList?.filter((item) => {
            return item?.emailAddress === enteredClinician;
          });
          clinicianData = { ...clinician?.[0] };
        }
        // }

        const mailTemplate = await getTemplateContent(
          TEMPPLATETYPE.CLIENTREASSIGN
        );
        const templateClientContent = mailTemplate[0].content;
        const updatedEmailClientContent = templateClientContent
          .replace(/%%ClinicianFirstName%%/g, clinicianData?.firstName)
          .replace(/%%ClinicianLastName%%/g, clinicianData?.lastName)
          .replace(/%%ClinicianEmail%%/g, clinicianData?.emailAddress)
          .replace(
            /%%AcceptClientAssignment%%/g,
            ` <a style='color:blue;text-decoration:underline;' href=${`http://localhost:3000/manageclient/${clientID}?client-reassign=true`}>Accept Client Assignment</a> `
          )
          .replace(
            /%%AssigningCliniciansName%%/g,
            userClinician?.firstName + " " + userClinician?.lastName
          )
          .replace(/%%ClientName%%/g, data?.firstName + " " + data?.lastName)
          // .replace(/%%ClientName%%/g, "client Name here")
          .replace(
            /%%AssigningCliniciansEmail%%/g,
            userClinician?.emailAddress
          );
        //
        await sendEmail(
          clinicianData?.emailAddress,
          mailTemplate[0].subject,
          updatedEmailClientContent,
          mailTemplate?.[0]?.bcc
        );
        Swal.fire(
          "Mail Sent!",
          `Mail has been sent to the Clinician`,
          "success"
        );
      } catch (error) {
        Swal.fire("Error!", "Something went wrong! Please try again.", "error");
      }
    } else {
      if (emailExists && !data?.customClaims?.role?.includes("clinician")) {
        Swal.fire(
          "Error!",
          "This email address does not belong to a clinician",
          "error"
        );
      } else if (
        !emailExists &&
        !data?.customClaims?.role?.includes("clinician")
      ) {
        Swal.fire("Error!", "This email address does not exist", "error");
      }
    }
  };

  const reassignClient = useCallback(async () => {
    try {
      // console.log("running 1")
      const userProfileDocRef = doc(
        collection(db, DATABASECOLLECTION.USERPROFILES),
        clientID
      );
      const otherSettingsCollectionRef = collection(
        userProfileDocRef,
        DATABASECOLLECTION.OTHERSETTINGS
      );
      // console.log("running 2")
      const clinicianIDUpdateDocRef = doc(
        otherSettingsCollectionRef,
        DATABASECOLLECTION.ACTIVATIONCODE
      );
      const activationCodeGet = await getDoc(clinicianIDUpdateDocRef);
      // console.log("running 3")
      // const clinicianUIDReference = doc(db, `${selectedClinician.trim()}`);
      if (activationCodeGet.data()) {
        const activationCodeGetData = activationCodeGet.data();
        const previousClinicianUID =
          activationCodeGetData.clinicianUID.path?.trim(); //get clinician uid from activation code collection
        const [previousClinicianCollection, previousClinicianDocID] =
          previousClinicianUID.split("/");
        // console.log("running 4", { previousClinicianCollection, previousClinicianDocID })
        const previousClinicianDocRef = doc(
          collection(db, previousClinicianCollection),
          previousClinicianDocID?.trim()
        );
        const previousClinicianCollectionRef = collection(
          previousClinicianDocRef,
          DATABASECOLLECTION.OTHERSETTINGS
        );
        const previousClinicianUpdateDocRef = doc(
          previousClinicianCollectionRef,
          "clientUID"
        );
        const previousClinicianGet = await getDoc(
          previousClinicianUpdateDocRef
        );
        // console.log("previousClinicianGet", { previousClinicianGet: previousClinicianGet.data() })
        let previousClinicianArr = previousClinicianGet.data().clientUIDs;

        // previousClinicianArr.forEach(async (docRef) => {
        //     const docSnapshot = await getDoc(docRef);
        //     if (docSnapshot.exists()) {
        //         const docData = docSnapshot.data();
        //         // Extract data from docData and use it as needed
        //         console.log('Document data:', docData);
        //     } else {
        //         console.error('Document does not exist:', docRef.path);
        //     }
        // });
        // previousClinicianArr = previousClinicianArr.map(item => doc(item))

        const updatedArray = previousClinicianArr.filter(
          (prevClc) =>
            `${DATABASECOLLECTION.USERPROFILES}/${clientID}` !==
            prevClc.path?.trim()
        );
        await updateDoc(previousClinicianUpdateDocRef, {
          clientUIDs: updatedArray,
        }); // update client UID from previous Clinician

        const selectedClinicianLocal = localStorage.getItem("docid");
        const userProfileClinicianDocRef = doc(
          collection(db, DATABASECOLLECTION.USERPROFILES),
          selectedClinicianLocal
        );
        const otherSettingsClinicianCollectionRef = collection(
          userProfileClinicianDocRef,
          DATABASECOLLECTION.OTHERSETTINGS
        );
        const clientUIDDocRef = doc(
          otherSettingsClinicianCollectionRef,
          "clientUID"
        );
        const clientUIDDocSnapshot = await getDoc(clientUIDDocRef);
        const clinicianUIDReference = doc(
          db,
          `${DATABASECOLLECTION.USERPROFILES}/${selectedClinicianLocal}`
        );
        if (clientUIDDocSnapshot.exists()) {
          // console.log("running 8")
          await setDoc(
            clientUIDDocRef,
            {
              clientUIDs: arrayUnion(
                doc(
                  db,
                  `${DATABASECOLLECTION.USERPROFILES}/${clientID?.trim()}`
                )
              ), // if key already exists, update client UID to new selected  Clinician
            },
            { merge: true }
          );
        } else {
          // console.log("running 9")
          await setDoc(clientUIDDocRef, {
            clientUIDs: [
              doc(db, `${DATABASECOLLECTION.USERPROFILES}/${clientID?.trim()}`),
            ], // if key not exists, update client UID from previous Clinician
          });
        }
        await updateDoc(clinicianIDUpdateDocRef, {
          clinicianUID: clinicianUIDReference, // update clinician UID in client profile
        });
      }
      // Swal.fire('Assigned!', 'Clinician has been assigned.', 'success');
      setQuery({ "client-reassign": "false" });
    } catch (error) {
      console.log("error reassign client", { error });
      Swal.fire("Error!", "Something went wrong! Please try again.", "error");
    }
    // }
  }, [clientID, setQuery]);

  useEffect(() => {
    if (
      query.get("client-reassign") === "true" &&
      clientID &&
      selectedClinician
    ) {
      reassignClient();
      getPatientDetail();
      // console.log("client-reassign", query.get("client-reassign"))
    }
  }, [clientID, getPatientDetail, query, reassignClient, selectedClinician]);

  const handleTRQ = async (e, key, side) => {
    const newValue = parseInt(e.target.value);
    setCanSaveCustomAudioGram(true);
    if (side === "left") {
      console.log("CURR LEFT TRQ", currentLeftAG);
      setCurrentCustomLeftAG((prevTRQ) => ({
        ...prevTRQ,
        [key]: newValue,
      }));
    } else if (side === "right") {
      setCurrentCustomRightAG((prevTRQ) => ({
        ...prevTRQ,
        [key]: newValue,
      }));
    }
  };

  const handleSelectAudiogram = async (e) => {
    const newValue = e.target.value;
    console.info(newValue);
    let mags = false;
    setSelectedAudiogram(newValue);
    if (newValue === "Custom") {
      mags = true;
    }
    const userAudioRef = doc(
      collection(db, DATABASECOLLECTION.USERAUDIO),
      uid.id
    );
    await firestoreTransaction(updateDoc, userAudioRef, {
      isUsingCustomAudiogram: mags,
    });
  };

  const saveAudiogram = async () => {
    setIsLoading(true);
    // const userProfleRef = doc(
    //   collection(db, DATABASECOLLECTION.USERPROFILES),
    //   uid.id
    // );
    const userAudioRef = doc(
      collection(db, DATABASECOLLECTION.USERAUDIO),
      uid.id
    );
    const timestampId = new Date().toISOString();
    const customAudiogramRef = doc(
      collection(userAudioRef, "customAudiograms"),
      timestampId
    );
    const audiogram = {
      audiogram: { left: currentCustomLeftAG, right: currentCustomRightAG },
      created: serverTimestamp(),
    };
    await firestoreTransaction(setDoc, customAudiogramRef, audiogram);
    await firestoreTransaction(updateDoc, userAudioRef, {
      currentCustomAudiogram: doc(
        collection(userAudioRef, "customAudiograms"),
        timestampId
      ),
      isUsingCustomAudiogram: true,
    });
    const data = {
      userId: uid.id,
      type: EventType.AUDIOGRAM,
      details: {
        date: audiogram.created,
        audiogram: audiogram.audiogram,
        ref: customAudiogramRef,
      },
    };
    await createEvent(data);
    // data.userId = selectedClinician.split('/').pop();;
    // delete data.details.clinicianUID;
    // data.details.clientUID = doc(db, DATABASECOLLECTION.USERPROFILES, uid.id);
    // await createEvent(data);
    setIsLoading(false);
    setCanSaveCustomAudioGram(false);
    setSelectedAudiogram("Custom");
    Swal.fire(
      "Updated!",
      "Custom audiogram has been updated successfully.",
      "success"
    );
  };

  const handlePhase = async (e) => {
    // handle phase data on change
    const inputValue = e.target.value;
    if (inputValue === "1" || inputValue === "2") {
      setPhase(inputValue);
      try {
        const userAudioRef = doc(
          collection(db, DATABASECOLLECTION.USERAUDIO),
          uid.id
        );
        const audioRefDoc = await getDoc(userAudioRef);
        let audioRefData = await audioRefDoc.data();
        if (inputValue === "1") {
          audioRefData.noise.enabled = true;
        } else if (inputValue === "2") {
          audioRefData.noise.enabled = false;
        }
        await updateDoc(userAudioRef, {
          phase: inputValue,
          noise: audioRefData.noise,
        });
        const data = {
          userId: uid.id,
          type: EventType.CLIENTSETTINGS,
          details: {
            settingChanged: "phase",
            value: inputValue,
            clinicianUID: doc(db, selectedClinician),
          },
        };
        await createEvent(data); // Create event for changes in phase by clinician in clinician userEvents

        data.userId = selectedClinician.split("/").pop();
        delete data.details.clinicianUID;
        data.details.clientUID = doc(
          db,
          DATABASECOLLECTION.USERPROFILES,
          uid.id
        );
        await createEvent(data); // Create event for changes in phase by clinician in client userEvents
        Swal.fire(
          "Updated!",
          "Phase has been updated successfully.",
          "success"
        );
      } catch (error) {
        console.log(error);
        Swal.fire("Error!", "Something went wrong! Please try again.", "error");
      }
    }
  };

  return (
    <>
      <div className="dashboardWrapper">
        <AdimnSidebar />
        <div className="adminDashBoard">
          <AdminHeader />
          <div className="adminBody">
            {isLoading && <Loader />}
            {!isLoading && (
              <div className="ManageClient commonCard">
                <div className="clientHeader">
                  <div className="row">
                    <div className="col-12">
                      <div className="Head">Manage Client</div>
                    </div>
                    <div className="col-12">
                      <div className="clintSide">
                        <div className="row justify-content-between">
                          <div className="row col-8">
                            <div className="col-6">
                              <div className="assignbtndiv">
                                {role &&
                                (role === ROLES.CLINICIAN ||
                                  role === ROLES.CLINICIANINCLINIC ||
                                  role === ROLES.CLINICIANINHOUSE) ? (
                                  <input
                                    type="email"
                                    placeholder="Enter Clinician Email"
                                    className="form-control"
                                    name="selectedClinician"
                                    value={enteredClinician}
                                    onChange={(event) =>
                                      setEnteredClinician(event.target.value)
                                    }
                                  />
                                ) : (
                                  <select
                                    className="form-control"
                                    name="selectedClinician"
                                    onChange={(event) =>
                                      setSelectedClinician(event.target.value)
                                    }
                                    value={selectedClinician}
                                  >
                                    <option value="">
                                      -- Choose Clinician --
                                    </option>
                                    {clinicianList &&
                                      clinicianList.map((item) => (
                                        <option
                                          key={item.uid}
                                          value={
                                            `${DATABASECOLLECTION.USERPROFILES}/` +
                                            item.uid
                                          }
                                          selected={
                                            `${DATABASECOLLECTION.USERPROFILES}/` +
                                              item.uid ===
                                            data.clinicianUID
                                          }
                                        >
                                          {item.firstName +
                                            " " +
                                            item.lastName +
                                            " (" +
                                            item.emailAddress +
                                            ")"}
                                        </option>
                                      ))}
                                  </select>
                                )}
                              </div>
                            </div>
                            <div className="col-4">
                              {role &&
                              (role === ROLES.CLINICIAN ||
                                role === ROLES.CLINICIANINCLINIC ||
                                role === ROLES.CLINICIANINHOUSE) ? (
                                <button
                                  className="btn btn-outline-dark mt-0"
                                  onClick={handleReAssign}
                                  // onClick={reassignClient}
                                >
                                  Re-assign Clinician
                                </button>
                              ) : (
                                <button
                                  className="btn btn-outline-dark mt-0"
                                  onClick={handleAssign}
                                >
                                  Assign Clinician
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="clientNames">
                              {data && data?.firstName + " " + data?.lastName}
                            </div>

                            {data && data.phone && (
                              <div className="contactClient">
                                <div>
                                  <span className="fw-bold">Phone: </span>
                                  {data.phone}
                                </div>
                              </div>
                            )}
                            <div className="clientEmail">
                              <span className="fw-bold"> Email:</span>{" "}
                              {data && data.emailAddress}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mainContent">
                  <div className="clientContent clientBorder mt-0">
                    <div className="heading d-flex justify-content-between">
                      <div>Tinnitus & Hearing Profile:</div>
                      {userClinician &&
                      userClinician?.firstName &&
                      userClinician?.lastName ? (
                        <div className="fs-6">
                          Clinician:{" "}
                          {userClinician &&
                            userClinician?.firstName +
                              " " +
                              userClinician?.lastName}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="row pt-3">
                      <div className="col-3 pe-0">
                        <div className="paraOne">
                          Tinnitus characterisation:
                        </div>
                      </div>
                      <div className="col-9">
                        <div className="formData">
                          {currentTinnitusCharactersation &&
                            currentTinnitusCharactersation.join(", ")}
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-3 pe-0">
                        <div className="paraOne">TRQ score:</div>
                      </div>
                      <div className="col-9">
                        <div className="formData">
                          {currentTRQ ? currentTRQ : "--"}
                          <Link
                            onClick={openModal}
                            className="pl-2 text-info"
                            style={{ paddingLeft: "10px" }}
                          >
                            View Result{" "}
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 448 512"
                              style={{ height: "10px", width: "10px" }}
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"></path>
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-3 pe-0">
                        <div className="paraOne">Calibrated Audiogram:</div>
                      </div>
                      <div className="col-9">
                        <div className="row">
                          {currentLeftAG && (
                            <div className="col-6">
                              <div className="table-responsive">
                                <table className="table audiogramDataTable table-bordered leftAudioGram">
                                  <thead>
                                    <tr>
                                      <th scope="col">500</th>
                                      <th scope="col">750</th>
                                      <th scope="col">1K</th>
                                      <th scope="col">2K</th>
                                      <th scope="col">3K</th>
                                      <th scope="col">4K</th>
                                      <th scope="col">6K</th>
                                      <th scope="col">8K</th>
                                      <th scope="col">10K</th>
                                      <th scope="col">12.5K</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      {currentLeftAG &&
                                        Object.keys(currentLeftAG).map(
                                          (key, index) => (
                                            <td key={index}>
                                              {currentLeftAG[key]}
                                            </td>
                                          )
                                        )}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="lefttxt">Left</div>
                            </div>
                          )}
                          {currentRightAG && (
                            <div className="col-6">
                              <div className="table-responsive">
                                <table className="table audiogramDataTable table-bordered rightAudioGram">
                                  <thead>
                                    <tr>
                                      <th scope="col">500</th>
                                      <th scope="col">750</th>
                                      <th scope="col">1K</th>
                                      <th scope="col">2K</th>
                                      <th scope="col">3K</th>
                                      <th scope="col">4K</th>
                                      <th scope="col">6K</th>
                                      <th scope="col">8K</th>
                                      <th scope="col">10K</th>
                                      <th scope="col">12.5K</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      {currentRightAG &&
                                        Object.keys(currentRightAG).map(
                                          (key, index) => (
                                            <td key={index}>
                                              {currentRightAG[key]}
                                            </td>
                                          )
                                        )}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="lefttxt">Right</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-3 pe-0">
                        <div className="paraOne">Custom Audiogram:</div>
                      </div>
                      <div className="col-9">
                        <div className="row">
                          {currentCustomLeftAG && (
                            <div className="col-6">
                              <div className="table-responsive">
                                <table className="table audiogramDataTable table-bordered leftcustomAudiogram">
                                  <thead>
                                    <tr>
                                      <th scope="col">500</th>
                                      <th scope="col">750</th>
                                      <th scope="col">1K</th>
                                      <th scope="col">2K</th>
                                      <th scope="col">3K</th>
                                      <th scope="col">4K</th>
                                      <th scope="col">6K</th>
                                      <th scope="col">8K</th>
                                      <th scope="col">10K</th>
                                      <th scope="col">12.5K</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      {currentLeftAG &&
                                        Object.keys(currentLeftAG).map(
                                          (key, index) => (
                                            <td key={index}>
                                              <input
                                                placeholder="0"
                                                min="-10"
                                                max="110"
                                                step="5"
                                                type="number"
                                                value={currentCustomLeftAG[key]}
                                                onChange={(e) => {
                                                  handleTRQ(e, key, "left");
                                                }}
                                              />
                                            </td>
                                          )
                                        )}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="lefttxt">Left</div>
                            </div>
                          )}
                          {currentCustomRightAG && (
                            <div className="col-6">
                              <div className="table-responsive">
                                <table className="table audiogramDataTable table-bordered rightcustomAudiogram">
                                  <thead>
                                    <tr>
                                      <th scope="col">500</th>
                                      <th scope="col">750</th>
                                      <th scope="col">1K</th>
                                      <th scope="col">2K</th>
                                      <th scope="col">3K</th>
                                      <th scope="col">4K</th>
                                      <th scope="col">6K</th>
                                      <th scope="col">8K</th>
                                      <th scope="col">10K</th>
                                      <th scope="col">12.5K</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      {currentRightAG &&
                                        Object.keys(currentRightAG).map(
                                          (key, index) => (
                                            <td key={index}>
                                              <input
                                                placeholder="0"
                                                min="-10"
                                                max="110"
                                                step="5"
                                                type="number"
                                                value={currentCustomRightAG[key]}
                                                onChange={(e) => {
                                                  handleTRQ(e, key, "right");
                                                }}
                                              />
                                            </td>
                                          )
                                        )}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="lefttxt">Right</div>
                            </div>
                          )}
                        </div>
                        <div>
                          <button
                            disabled={!canSaveCustomAudioGram}
                            className="btn btn-outline-dark mt-0"
                            onClick={saveAudiogram}
                          >
                            Save Audiogram
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-3 pe-0">
                        <div className="paraOne">Selected Audiogram:</div>
                      </div>
                      <div className="col-9">
                        <select
                          className="form-control sel-ag"
                          name="selectedAudiogram"
                          onChange={(event) => handleSelectAudiogram(event)}
                          value={selectedAudioGram}
                        >
                          <option>Calibrated</option>
                          <option>Custom</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="clientContent">
                    <div className="heading">Treatment Settings (current):</div>
                    <div className="row pt-3 py-1">
                      <div className="col-3 pe-0">
                        <div className="paraOne">Phase:</div>
                      </div>
                      <div className="col-9">
                        <div className="formData">
                          <input
                            className=""
                            type="number"
                            min="1"
                            max="2"
                            value={phase}
                            onChange={(e) => {
                              handlePhase(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-3 pe-0">
                        <div className="paraOne">Neural Stimulus</div>
                      </div>
                      <div className="col-9">
                        <div className="row gy-3">
                          <div className="col-2">
                            <div className="formData dbColored">
                              {neuralStimulus}dB
                            </div>
                          </div>
                          <div className="col-1">
                            <div className="dbtxt mt-0">-10dB</div>
                          </div>
                          <div className="col-8">
                            <RangeSlider
                              id="neural-stimulus1-range"
                              selectedClinician={selectedClinician}
                              onChange={handleNeuralStimulusChange}
                              initValue={
                                treatmentSettings
                                  ? treatmentSettings.neuralStimulus || 0
                                  : 0
                              }
                              name="neuralStimulus"
                              uid={clientID}
                            />
                          </div>
                          <div className="col-1">
                            <div className="dbtxt mt-0">+10dB</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-3 pe-0">
                        <div className="paraOne">Balance:</div>
                      </div>
                      <div className="col-9">
                        <div className="row gy-3">
                          <div className="col-2">
                            <div className="formData dbColored">
                              {balance} {balance < 0 ? "Left" : "Right"}
                            </div>
                          </div>
                          <div className="col-1">
                            <div className="dbtxt mt-0">Left</div>
                          </div>
                          <div className="col-8">
                            <RangeSlider
                              id="neural-stimulus2-range"
                              selectedClinician={selectedClinician}
                              onChange={handleBalanceChange}
                              initValue={
                                treatmentSettings
                                  ? treatmentSettings.balance || 0
                                  : 0
                              }
                              name="balance"
                              uid={clientID}
                            />
                          </div>
                          <div className="col-1">
                            <div className="dbtxt mt-0">Right</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clientContent">
                    <div className="heading">Timeline:</div>
                    <div>
                      {events &&
                        Object.entries(events).map((el, index) => {
                          const [isoWeek, items] = el;
                          return (
                            <div key={isoWeek}>
                              <EventGraph
                                eventData={items}
                                week={index}
                                nextData={
                                  Object.entries(events)?.[index + 1]?.[1]?.[0]
                                }
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <ReactModal
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                  contentLabel="Your Modal"
                  className="custom-modal"
                  style={{
                    content: {
                      width: "50%",
                      height: "80%",
                      margin: "auto",
                      left: "356px",
                      marginTop: "55px",
                      backgroundColor: "#ffffff",
                      border: "1px solid",
                      overflow: "auto",
                    },
                    overlay: {
                      backgroundColor: "rgb(255 255 255 / 88%)",
                      zIndex: "99999",
                    },
                  }}
                >
                  <ModalHeader
                    style={{
                      padding: "10px",
                      background: "#000",
                      color: "#fff",
                    }}
                    className="row gx-0"
                  >
                    <div className="col">TRQ Data</div>
                    <div className="col-auto">
                      <Link className="text-light" onClick={closeModal}>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 24 24"
                          style={{ height: "24px", width: "24px" }}
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"></path>
                        </svg>
                      </Link>
                    </div>
                  </ModalHeader>
                  <ModalBody
                    style={{
                      overflow: "auto",
                      maxHeight: "73%%",
                      padding: "10px",
                    }}
                  >
                    <TRQModal TRQResponse={TRQData} />
                  </ModalBody>
                </ReactModal>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageClient;
