const NUMBER_OF_WORDS = 4;

// Soundex algorithm implementation
export function soundex(s) {
  const a = s.toLowerCase().split("");
  const f = a.shift();
  let r = "";
  const codes = {
    a: "",
    e: "",
    i: "",
    o: "",
    u: "",
    b: 1,
    f: 1,
    p: 1,
    v: 1,
    c: 2,
    g: 2,
    j: 2,
    k: 2,
    q: 2,
    s: 2,
    x: 2,
    z: 2,
    d: 3,
    t: 3,
    l: 4,
    m: 5,
    n: 5,
    r: 6,
  };
  r =
    f +
    a
      .map((v) => codes[v])
      .filter((v, i, b) => (i === 0 ? v !== codes[f] : v !== b[i - 1]))
      .join("");
  return (r + "000").slice(0, 4).toUpperCase();
}

// Create word groups of specified size
const createIndex = (text) => {
  const finalArray = [];
  const wordArray = text
    .toLowerCase()
    .replace(/[^\p{L}\p{N}]+/gu, " ")
    .replace(/ +/g, " ")
    .trim()
    .split(" ");

  do {
    finalArray.push(wordArray.slice(0, NUMBER_OF_WORDS).join(" "));
    wordArray.shift();
  } while (wordArray.length !== 0);

  return finalArray;
};

// Create fuzzy search index for a given text
export const createSearchIndex = (text) => {
  const m = {};
  let index = createIndex(text);

  // Convert to soundex
  const temp = [];
  for (const i of index) {
    temp.push(
      i
        .split(" ")
        .map((v) => soundex(v))
        .join(" ")
    );
  }
  index = temp;

  // Create indexed entries
  for (const phrase of index) {
    if (phrase) {
      let v = "";
      const t = phrase.split(" ");
      while (t.length > 0) {
        const r = t.shift();
        v += v ? " " + r : r;
        m[v] = m[v] ? m[v] + 1 : 1;
      }
    }
  }
  return m;
};

// Create search index for a user profile
export const createUserSearchIndex = (userData) => {
  const searchableText = `${userData.firstName} ${userData.lastName} ${userData.emailAddress}`;
  return createSearchIndex(searchableText);
};
