import React, { useEffect, useState } from "react";
import AdimnSidebar from "../../components/adminSidebar/AdminSidebar";
import AdminHeader from "../../components/adminHeader/AdminHeader";

import { db } from "../../common/firebase";
import { useParams } from "react-router-dom";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { setRoleData } from "../../common/fetch";
import { ROLES } from "../../constants/RoleConst";
import Alert from "../../components/alert/Alert";
import { getTemplateContent } from "../../common/helper";
import { TEMPPLATETYPE } from "../../constants/TemplateTypeConst";
import { DATABASECOLLECTION } from "../../constants/DatabaseConst";

const EditClinician = () => {
  const uid = useParams();
  const [data, setData] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [onboardComplete, setOnboardComplete] = useState(false);
  const [onboardInitialStatus, setOnboardInitialStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  useEffect(() => {
    const getClinician = async () => {
      const usersCollection = collection(db, DATABASECOLLECTION.USERPROFILES);
      const userDocRef = doc(usersCollection, uid.uid);
      const userDocumentSnapshot = await getDoc(userDocRef);
      if (userDocumentSnapshot.data()) {
        const userDetails = userDocumentSnapshot.data();
        const stateRef = collection(userDocRef, DATABASECOLLECTION.STATE);
        const currentDocRef = doc(stateRef, DATABASECOLLECTION.CURRENT);
        const currentDocSnap = await getDoc(currentDocRef);
        userDetails.status = currentDocSnap.data();
        setOnboardComplete(userDetails.status.onboardComplete);
        setOnboardInitialStatus(userDetails.status.onboardComplete);
        setData(userDetails);
        setFirstName(userDetails.firstName);
        setLastName(userDetails.lastName);
      }
    };
    getClinician();
  }, [uid.uid]);

  const handleUpdateStatus = async () => {
    try {
      const clinicianTemplate = await getTemplateContent(
        TEMPPLATETYPE.CLINICIANSTATUS
      );
      const templateContent = clinicianTemplate[0].content;
      const updatedEmailContent = templateContent
        .replace(/%%FirstName%%/g, firstName)
        .replace(/%%LastName%%/g, lastName);
      console.log(updatedEmailContent);
      const clinicianRef = doc(
        collection(db, DATABASECOLLECTION.USERPROFILES),
        uid.uid
      );
      const stateRef = collection(clinicianRef, DATABASECOLLECTION.STATE);
      const currentDocRef = doc(stateRef, DATABASECOLLECTION.CURRENT);
      await setRoleData(uid.uid, ROLES.CLINICIAN);
      await updateDoc(currentDocRef, {
        completedHearingTest: false,
        completedTRQ: false,
        connectedClinician: false,
        onboardComplete: onboardComplete === "approved" ? true : false,
      });
      // const userProfileClinicianDocData = await getDoc(clinicianRef);
      // const userProfileData = userProfileClinicianDocData.data();

      // const clinicianTemplate = await getTemplateContent(TEMPPLATETYPE.CLINICIANSTATUS);
      // const templateContent = clinicianTemplate[0].content;
      // const updatedEmailContent = templateContent.replace(/%%FirstName%%/g, firstName)
      //     .replace(/%%LastName%%/g, lastName);
      // await sendEmail(userProfileData.emailAddress, clinicianTemplate[0].subject, updatedEmailContent, clinicianTemplate[0].bcc);

      setSuccessMsg("Clincian status updated successfully!");
      return;
    } catch (error) {
      setErrorMsg("An error occurred during updation. Please try again.");
    }
  };

  return (
    <>
      <div className="dashboardWrapper">
        <AdimnSidebar />
        <div className="adminDashBoard">
          <AdminHeader />
          {data && (
            <div className="adminBody">
              <div className="registerUser commonCard">
                <div className="row">
                  <div className="col-12">
                    <div className="card-header ">
                      <div className="SectionHeadingMain">
                        Clinician Details
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    {errorMsg && <Alert text={errorMsg} type="error" />}
                    {successMsg && <Alert text={successMsg} type="success" />}
                    <div className="card-body pt-3">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control shadow-none border formcontrolCustom controlRounded"
                              placeholder=""
                              value={data.firstName}
                              readOnly
                            />
                            <label
                              htmlFor="floatingInput"
                              className="formControlLable"
                            >
                              <div className="labelTxt">First Name</div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control shadow-none border formcontrolCustom controlRounded"
                              value={data.lastName}
                              placeholder=""
                              readOnly
                            />
                            <label
                              htmlFor="floatingInput"
                              className="formControlLable"
                            >
                              <div className="labelTxt">Last Name</div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="email"
                              className="form-control shadow-none border formcontrolCustom controlRounded"
                              value={data.emailAddress}
                              placeholder=""
                              readOnly
                            />
                            <label
                              htmlFor="floatingInput"
                              className="formControlLable"
                            >
                              <div className="labelTxt">Email</div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control shadow-none border formcontrolCustom controlRounded"
                              value={data.firstName + " " + data.lastName}
                              placeholder=""
                              readOnly
                            />
                            <label
                              htmlFor="floatingInput"
                              className="formControlLable"
                            >
                              <div className="labelTxt">Public Name</div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control shadow-none border formcontrolCustom controlRounded"
                              value={data.phone}
                              placeholder=""
                              readOnly
                            />
                            <label
                              htmlFor="floatingInput"
                              className="formControlLable"
                            >
                              <div className="labelTxt">Phone</div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control shadow-none border formcontrolCustom controlRounded"
                              value={data.country}
                              placeholder=""
                              readOnly
                            />
                            <label
                              htmlFor="floatingInput"
                              className="formControlLable"
                            >
                              <div className="labelTxt">Country</div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control shadow-none border formcontrolCustom controlRounded"
                              value={data.state}
                              placeholder=""
                              readOnly
                            />
                            <label
                              htmlFor="floatingInput"
                              className="formControlLable"
                            >
                              <div className="labelTxt">State</div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control shadow-none border formcontrolCustom controlRounded"
                              value={data.qualifications}
                              placeholder=""
                              readOnly
                            />
                            <label
                              htmlFor="floatingInput"
                              className="formControlLable"
                            >
                              <div className="labelTxt">Qualifications</div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <div className="form-floating mb-3">
                            <textarea
                              className="form-control shadow-none border formcontrolCustom controlRounded"
                              value={data.message}
                              placeholder=""
                              cols="3"
                              readOnly
                            >
                              {data.message}
                            </textarea>
                            <label
                              htmlFor="floatingInput"
                              className="formControlLable"
                            >
                              <div className="labelTxt">Message</div>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <div className="form-floating mb-3">
                            {!onboardInitialStatus ? (
                              <select
                                className="form-control shadow-none border formcontrolCustom controlRounded"
                                name="onboardComplete"
                                value={onboardComplete}
                                onChange={(event) =>
                                  setOnboardComplete(event.target.value)
                                }
                              >
                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                              </select>
                            ) : (
                              <input
                                type="text"
                                className="form-control shadow-none border formcontrolCustom controlRounded"
                                value="Approved"
                                placeholder=""
                                readOnly
                              />
                            )}
                            <label
                              htmlFor="floatingInput"
                              className="formControlLable"
                            >
                              <div className="labelTxt">Status</div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!onboardInitialStatus && (
                    <div className="col-8 col-sm-6 col-md-3 col-xl-3 mx-auto mx-sm-0">
                      <div className="signInButton mt-md-3 text-center">
                        <button
                          className="signinBtn rounded-2"
                          type="btn"
                          onClick={handleUpdateStatus}
                        >
                          Update Status
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditClinician;
