export const EventType = {
    WEBPAGE: 'webpage',
    ENQUIRY: 'enquiry',
    LOGIN: 'login',
    CLIENTSETTINGS: 'clientSettings',
    LOGOUT:'logout',
    EDITCOUPON:'editCoupon',
    ALTTREATMENT: 'alternative treatment request',
    ADDADMIN:'added new admin',
    AUDIOGRAM:'audiogram'
}