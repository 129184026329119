import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../../components/frontendComponents/header/Header";
import Footer from "../../../components/frontendComponents/footer/Footer";
import tabletImage from "../../../assets/common/img/tablet.png";
import appStoreImage from "../../../assets/common/img/app-white.png";
import { getAuth, signInAnonymously, signOut } from "firebase/auth";
import { checkEmailExists, getTemplateContent } from "../../../common/helper";
import { getData, sendEmail } from "../../../common/fetch";
import { createEvent } from "../../../common/helper";
import { Link } from "react-router-dom";
import axios from "axios";
import { MAP_API, MAP_GEOCODE_URL } from "../../../common/map";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../common/firebase";
import { FILENAME, STORAGE, STORAGEURL } from "../../../constants/StorageConst";
import FloatingInput from "../commonComponents/FloatingInput";
import Alert from "../../../components/alert/Alert";
import { EventType } from "../../../constants/EventConst";
import { TEMPPLATETYPE } from "../../../constants/TemplateTypeConst";

const FindPage = () => {
  const scrollContainer = useRef(null);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [, setUserLocation] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [message, setMessage] = useState("");

  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const [jsonData, setJsonData] = useState(null);
  const [, setIsLoading] = useState(true);
  const [, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const center = {
    lat: 15,
    lng: 10,
  };

  const monochromeMapStyle = [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        // { saturation: 100 },
        { lightness: 20 },
        { hue: "#517991" },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [{ color: "#ffffff" }, { weight: 1 }],
    },
  ];

  const mapOptions = {
    styles: monochromeMapStyle,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAP_API,
  });

  // const onLoad = useCallback(function callback(map) {
  //     const bounds = new window.google.maps.LatLngBounds(center);
  //     map.fitBounds(bounds);
  //     setMap(map)
  // }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const jsonFileRef = ref(
        storage,
        STORAGEURL + "/" + STORAGE.CLINICS + "/" + FILENAME.CLINICSFILENAME
      );

      try {
        const url = await getDownloadURL(jsonFileRef);
        const response = await fetch(url);
        const data = await response.json();

        let clinicsWithDistance = data;

        if (selectedLocation && isClicked) {
          const result = await getLatLngByZipCode(selectedLocation);
          if (result.lat && result.lng) {
            clinicsWithDistance = data.map((clinic) => {
              const distance = calculateDistance(
                clinic.lat,
                clinic.lng,
                result.lat,
                result.lng
              );
              return { ...clinic, distance };
            });
            clinicsWithDistance = clinicsWithDistance.filter(
              (clinic) => clinic.distance <= 60
            );
          }
          // clinicsWithDistance.filter(clinic => {
          //     return (
          //         clinic.clinicName.toLowerCase().includes(selectedLocation.toLowerCase()) ||
          //         clinic.country.toLowerCase().includes(selectedLocation.toLowerCase()) ||
          //         clinic.zipCode.includes(selectedLocation) ||
          //         clinic.clinicAddress.toLowerCase().includes(selectedLocation.toLowerCase())
          //     );
          // });
        } else {
          // const filteredClinics = clinicsWithDistance;
        }
        // const filteredClinics = clinicsWithDistance.filter(clinic => {
        //     return (
        //         clinic.clinicName.toLowerCase().includes(selectedLocation.toLowerCase()) ||
        //         clinic.country.toLowerCase().includes(selectedLocation.toLowerCase()) ||
        //         clinic.zipCode.includes(selectedLocation) ||
        //         clinic.clinicAddress.toLowerCase().includes(selectedLocation.toLowerCase())
        //     );
        // });
        setJsonData(clinicsWithDistance);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching or parsing JSON file:", error);
      }
    };
    fetchData();
  }, [selectedLocation, isClicked]);

  // Get the Latitude and Longitude of the selected location
  const getLatLngByZipCode = async (zipCode) => {
    if (zipCode) {
      try {
        const response = await axios.get(MAP_GEOCODE_URL, {
          params: {
            address: `${zipCode}`,
            key: MAP_API,
          },
        });
        const { results } = response.data;
        if (results.length > 0) {
          const { lat, lng } = results[0].geometry.location;
          return { lat, lng };
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
    return true;
  };

  // calculate the distance between the selected location and clinic location
  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lng1 - lng2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515; // Convert to miles

    return dist.toFixed(2);
  };

  //customized svg icon for marker
  const svgIcon =
    '<svg width="70" height="70" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx = "20.6719" cy = "16.7344" r = "5.57812" fill = "white" /><path d="M21 6.77734C15.5636 6.77734 11.1562 11.1847 11.1562 16.6211C11.1562 20.9556 17.4149 30.4765 19.9513 34.1581C20.5236 34.9882 21.4764 34.9882 22.0487 34.1581C24.5851 30.4758 30.8438 20.9563 30.8438 16.6211C30.8438 11.1847 26.4364 6.77734 21 6.77734ZM21 11.3711C22.3924 11.3711 23.7277 11.9242 24.7123 12.9088C25.6969 13.8933 26.25 15.2287 26.25 16.6211C26.25 17.3105 26.1142 17.9932 25.8504 18.6302C25.5865 19.2671 25.1998 19.8459 24.7123 20.3334C24.2248 20.8209 23.646 21.2076 23.0091 21.4715C22.3721 21.7353 21.6894 21.8711 21 21.8711C19.6076 21.8711 18.2723 21.318 17.2877 20.3334C16.3031 19.3488 15.75 18.0135 15.75 16.6211C15.75 15.2287 16.3031 13.8933 17.2877 12.9088C18.2723 11.9242 19.6076 11.3711 21 11.3711Z" fill="#2C2B6D" /></svg >';

  // styled the marker icon
  const markerIcon = {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgIcon)}`,
    scaledSize: new window.google.maps.Size(50, 50),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(20, 40),
  };

  // const handleLocation = (newValue) => {
  //     setSelectedLocation(newValue);
  // };

  useEffect(() => {
    const options = {
      fields: ["address_components", "geometry", "icon", "name"],
    };

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      await autoCompleteRef.current.getPlace();
    });
  }, []);

  const useMyLocation = async () => {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const { latitude, longitude } = position.coords;

      setUserLocation({
        lat: latitude,
        lng: longitude,
      });

      const response = await axios.get(MAP_GEOCODE_URL, {
        params: {
          latlng: `${latitude},${longitude}`,
          key: MAP_API,
        },
      });

      const addressComponents = response.data.results[0].address_components;
      const zipcodeComponent = addressComponents.find((component) =>
        component.types.includes("postal_code")
      );

      setSelectedLocation(
        zipcodeComponent ? zipcodeComponent.short_name : null
      );
    } catch (error) {
      console.error("Error getting user location or ZIP code:", error);
    }
  };

  // Handle the enquire by Unauthorized or Authorized user from Public web
  const handleEnquire = async () => {
    try {
      setErrorMsg("");
      setSuccessMsg("");
      setIsLoading(true);
      const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
      };

      const mandatoryFields = [firstName, email, message];

      for (const field of mandatoryFields) {
        if (!field) {
          setIsLoading(false);
          setErrorMsg(
            "Please complete all mandatory fields (indicated by an asterisk)."
          );
          return;
        }
      }

      if (!isValidEmail(email)) {
        setIsLoading(false);
        setErrorMsg(
          "Invalid email format. Please enter a valid email address."
        );
        return;
      }

      const auth = getAuth();
      const checkClientEmailExists = await checkEmailExists(email);
      const userId = checkClientEmailExists
        ? (await getData(email)).uid
        : (await signInAnonymously(auth)).user.uid;

      const data = {
        userId: userId,
        type: EventType.ENQUIRY,
        details: {
          firstName: firstName,
          emailAddress: email,
          state: state,
          country: country,
          message: message,
        },
      };

      await createEvent(data); //create event for enquiry data by unauthorized user from public website

      const adminTemplate = await getTemplateContent(
        TEMPPLATETYPE.WEBSITEENQUIRYADMIN
      );
      const templateAdminContent = adminTemplate[0].content;
      const updatedEmailAdminContent = templateAdminContent
        .replace(/%%FirstName%%/g, firstName)
        .replace(/%%Email%%/g, email)
        .replace(/%%State%%/g, state)
        .replace(/%%Country%%/g, country)
        .replace(/%%Message%%/g, message);

      const clientTemplate = await getTemplateContent(
        TEMPPLATETYPE.WEBSITEENQUIRYCLIENT
      );
      const templateClientContent = clientTemplate[0].content;
      const updatedEmailClientContent = templateClientContent.replace(
        /%%FirstName%%/g,
        firstName
      );

      await sendEmail(
        adminTemplate[0].recipient,
        adminTemplate[0].subject,
        updatedEmailAdminContent,
        adminTemplate[0].bcc
      );
      await sendEmail(
        email,
        clientTemplate[0].subject,
        updatedEmailClientContent,
        adminTemplate[0].bcc
      );

      setErrorMsg("");
      // setFirstName('');
      // setEmail('');
      // setState('');
      // setCountry('');
      // setMessage('');
      setIsLoading(false);
      setSuccessMsg("Enquiry sent successfully!");
      await signOut(auth);
    } catch (error) {
      setIsLoading(false);
      if (error.code === "auth/email-already-in-use") {
        setErrorMsg(
          "This email is already in use. Please use a different email."
        );
      } else {
        console.log(error);
        setErrorMsg("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <div className="min-h-screen bg-white" ref={scrollContainer}>
      <Header scrollContainer={scrollContainer} secondHeader={true} />

      {/* Hero Section */}
      <div className="w-full py-8 bg-white mt-16">
        <div className="max-w-7xl mx-auto px-4">
          <h1 className="text-3xl font-bold text-center text-navy-800 mb-4">
            Find a Neuromonics Clinics Near You
          </h1>
          <p className="text-center text-gray-600 text-lg">
            Neuromonics Clinics are available in the United States of America
            and Australia.
          </p>
        </div>
      </div>

      {/* Map and Search Section */}
      <div className="w-full bg-white">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col sm:flex-row">
            {/* Search Section - 1/3 width */}
            <div className="w-full sm:w-1/3 bg-white">
              {/* Search Input Group */}
              <div className="p-4">
                <div className="relative flex items-center bg-white border rounded-lg shadow-sm">
                  <div className="absolute left-3">
                    <button
                      onClick={useMyLocation}
                      className="p-2 hover:bg-gray-100 rounded-full"
                    >
                      <svg
                        className="w-5 h-5 text-gray-500"
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3A8.994 8.994 0 0 0 13 3.06V1h-2v2.06A8.994 8.994 0 0 0 3.06 11H1v2h2.06A8.994 8.994 0 0 0 11 20.94V23h2v-2.06A8.994 8.994 0 0 0 20.94 13H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
                      </svg>
                    </button>
                  </div>
                  <input
                    type="text"
                    className="w-full pl-12 pr-10 py-2 text-gray-700 focus:outline-none"
                    placeholder="Enter a country, city, county, town, or zip code"
                    value={selectedLocation}
                    ref={inputRef}
                    onChange={(event) =>
                      setSelectedLocation(event.target.value)
                    }
                  />
                  <button
                    className="absolute right-3 p-2 hover:bg-gray-100 rounded-full"
                    onClick={() => setIsClicked(true)}
                  >
                    <svg
                      className="w-5 h-5 text-gray-500"
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                    >
                      <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34z" />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Results List */}
              <div className="overflow-y-auto h-[calc(100vh-300px)]">
                {jsonData && jsonData.length === 0 ? (
                  <div className="p-4">
                    <h3 className="text-lg font-semibold mb-2">
                      There are no Neuromonics clinics near you
                    </h3>
                    {/* ... Contact form remains the same ... */}
                  </div>
                ) : (
                  jsonData?.map((data, index) => (
                    <div key={index} className="p-4 border-b hover:bg-gray-50">
                      <h3 className="text-lg font-semibold text-navy-800 mb-2">
                        {data.clinicName}
                      </h3>
                      <p className="text-gray-600 mb-3">{data.clinicAddress}</p>

                      {data.distance && (
                        <div className="flex items-center text-gray-500 mb-3">
                          <svg
                            className="w-4 h-4 mr-2" /* ... location icon ... */
                          />
                          <span>{data.distance} mi</span>
                        </div>
                      )}

                      {data.phone && (
                        <div className="text-gray-600 mb-1">
                          T:{" "}
                          <Link
                            to={`tel:${data.phone}`}
                            className="text-blue-600 hover:text-blue-800"
                          >
                            {data.phone}
                          </Link>
                        </div>
                      )}

                      {data.email && (
                        <div className="text-gray-600 mb-3">
                          E:{" "}
                          <Link
                            to={`mailto:${data.email}`}
                            className="text-blue-600 hover:text-blue-800"
                          >
                            {data.email}
                          </Link>
                        </div>
                      )}

                      <Link
                        to={`https://${data.URL}`}
                        className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
                        target="_blank"
                      >
                        Visit Website
                        <svg
                          className="w-4 h-4 ml-2" /* ... arrow icon ... */
                        />
                      </Link>
                    </div>
                  ))
                )}
              </div>
            </div>

            {/* Map Section - 2/3 width */}
            <div className="hidden sm:block sm:w-2/3">
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    height: "calc(100vh - 200px)",
                    borderRadius: "0px",
                  }}
                  center={center}
                  zoom={2.3}
                  onUnmount={onUnmount}
                  options={mapOptions}
                >
                  {jsonData?.map((item, index) => (
                    <Marker
                      key={index}
                      position={{ lat: item.lat, lng: item.lng }}
                      icon={markerIcon}
                      onClick={() => setSelectedMarker(item)}
                    />
                  ))}

                  {selectedMarker && (
                    <InfoWindow
                      position={{
                        lat: selectedMarker.lat,
                        lng: selectedMarker.lng,
                      }}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <div className="p-3">
                        <h3 className="font-semibold mb-2">
                          {selectedMarker.clinicName}
                        </h3>
                        <p className="text-sm text-gray-600 mb-2">
                          {selectedMarker.clinicAddress}
                        </p>
                        {selectedMarker.email && (
                          <div className="text-sm mb-1">
                            E:{" "}
                            <a
                              href={`mailto:${selectedMarker.email}`}
                              className="text-blue-600"
                            >
                              {selectedMarker.email}
                            </a>
                          </div>
                        )}
                        {selectedMarker.phone && (
                          <div className="text-sm mb-2">
                            T:{" "}
                            <a
                              href={`tel:${selectedMarker.phone}`}
                              className="text-blue-600"
                            >
                              {selectedMarker.phone}
                            </a>
                          </div>
                        )}
                        <a
                          href={`https://${selectedMarker.URL}`}
                          className="inline-block px-3 py-1 bg-blue-600 text-white text-sm rounded hover:bg-blue-700"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Visit Website
                        </a>
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <div className="w-full py-8 bg-white mt-16">
        <div className="max-w-7xl mx-auto px-4">
          <h1 className="text-3xl font-bold text-center text-navy-800 mb-4">
            Don't see a Neuromonics Clinic Near You?
          </h1>
          <p className="text-center text-gray-600 text-lg">
            Fill out the form below and a Neuromon
          </p>
        </div>
      </div>

      <section
        className="grid lg:grid-cols-2 gap-12 items-start max-w-6xl mx-auto"
        aria-labelledby="registration-heading"
      >
        {/* Left Column - Form */}
        <div className="bg-white p-8">
          <h1
            id="registration-heading"
            className="text-2xl text-[#2d4b6d] font-medium mb-8"
          >
            Start Your Tinnitus Treatment Today
          </h1>
          <div
            className="space-y-2"
            aria-label="Registration form"
            // onSubmit={handleSubmit}
          >
            {errorMsg && <Alert text={errorMsg} type="error" />}
            {successMsg && <Alert text={successMsg} type="success" />}
            <FloatingInput
              id="name"
              label="Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <FloatingInput
              id="email"
              type="email"
              label="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <div className="grid grid-cols-2 gap-4">
              <FloatingInput
                id="country"
                label="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
              />
              <FloatingInput
                id="state"
                label="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
              />
            </div>

            {/* Message Textarea */}
            <div className="w-full">
              <div className="relative mb-3">
                <textarea
                  className="peer h-32 w-full rounded-md border border-gray-300 bg-transparent px-3 pt-5 text-gray-900 placeholder-transparent focus:border-[#517991] focus:outline-none focus:ring-1 focus:ring-[#517991]"
                  placeholder="Message"
                  name="message"
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <label
                  htmlFor="message"
                  className="pointer-events-none absolute left-3 top-1 text-xs text-gray-500 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-[14px] peer-focus:top-1 peer-focus:text-xs"
                >
                  Message <span className="text-red-500">*</span>
                </label>
              </div>
            </div>

            <button
              onClick={handleEnquire}
              className="w-32 bg-gray-300 hover:bg-gray-400 text-primary px-6 py-3 rounded-md transition-colors"
            >
              Submit
            </button>
          </div>
        </div>

        {/* Right Column - App Preview */}
        <div className="relative mt-8 lg:mt-0">
          <div className="flex justify-center items-start gap-6">
            <img
              src={tabletImage}
              alt="Neuromonics tinnitus treatment app interface preview"
              className="w-full rounded-2xl"
              width="600"
              height="800"
              loading="eager"
            />
          </div>
          <div className="mt-6 text-center flex justify-center gap-4">
            <img
              src={appStoreImage}
              alt="Download Neuromonics on the App Store"
              className="inline-block w-20 md:w-24 h-auto"
              width="96"
              height="32"
              loading="lazy"
            />
            <p className="text-2xl text-primary font-roboto leading-[31px] mt-2">
              iOS 17.0 or newer is required for app.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default FindPage;
