import React, { useRef } from "react";
import Header from "../../../components/frontendComponents/header/Header";
import Footer from "../../../components/frontendComponents/footer/Footer";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  const scrollContainer = useRef(null);
  return (
    <div className="" ref={scrollContainer}>
      <Header scrollContainer={scrollContainer} secondHeader={true} />
      <div className="container mx-auto px-8 pt-32 pb-16 sm:px-10 lg:px-16">
        {/* Header Section */}
        <div className="bg-white p-6 mb-8 rounded-lg shadow-sm">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Neuromonics Privacy Policy
          </h1>
          <p className="text-sm text-gray-500 mb-4">
            Effective Date: January 1, 2024
          </p>
          <p className="text-gray-600 leading-relaxed">
            This Privacy Policy outlines how Neuromonics ("we", "us", or "our")
            collects, uses, discloses, and safeguards your information when you
            use our application (the "App"). This policy is designed to ensure
            the protection of your privacy, and enable us to provide enhanced
            features through responsible data sharing practices.
          </p>
        </div>

        {/* Main Content */}
        <div className="bg-white p-6 space-y-8 rounded-lg shadow-sm">
          {/* Information Collection */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                1
              </span>
              Information Collection
            </h2>
            <div className="prose max-w-none text-gray-600 ml-11">
              <p className="mb-4">
                We collect information that you provide directly to us when you
                use the App, including but not limited to:
              </p>
              <ul className="space-y-2">
                <li className="flex items-start">
                  <span className="text-blue-600 mr-2">•</span>
                  <span>
                    <strong className="text-gray-900">
                      Personal Identification Information:
                    </strong>{" "}
                    Name, email address, and other contact details.
                  </span>
                </li>
                <li className="flex items-start">
                  <span className="text-blue-600 mr-2">•</span>
                  <span>
                    <strong className="text-gray-900">
                      Health and Medical Information:
                    </strong>{" "}
                    Specific data related to your tinnitus condition and
                    treatment progress.
                  </span>
                </li>
                <li className="flex items-start">
                  <span className="text-blue-600 mr-2">•</span>
                  <span>
                    <strong className="text-gray-900">
                      Device and Usage Information:
                    </strong>{" "}
                    Information about how you use the App and your device,
                    including the operating system, device identifiers, and
                    usage patterns.
                  </span>
                </li>
              </ul>
            </div>
          </section>

          {/* Use of Information */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                2
              </span>
              Use of Information
            </h2>
            <div className="prose max-w-none text-gray-600 ml-11">
              <p className="mb-4">The information we collect is used to:</p>
              <ul className="space-y-2">
                {[
                  "Provide, maintain, and improve our services.",
                  "Personalize your App experience.",
                  "Communicate with you, including responding to your inquiries, comments, and requests for customer service.",
                  "Enhance the security of the App.",
                  "Comply with legal obligations",
                  "With your consent, enhance your experience of Neuromonics or third-party products you are using.",
                ].map((item, index) => (
                  <li key={index} className="flex items-start">
                    <span className="text-blue-600 mr-2">•</span>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          {/* Information Sharing and Disclosure */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                3
              </span>
              Information Sharing and Disclosure
            </h2>
            <div className="prose max-w-none text-gray-600 ml-11 space-y-4">
              <p>
                <strong className="text-gray-900">Service Providers:</strong> We
                may share your information with third-party entities that
                perform services on our behalf, such as data analysis, customer
                service, and other operations necessary for the App's
                functioning.
              </p>
              <p>
                <strong className="text-gray-900">
                  Aggregated and Anonymized Data:
                </strong>{" "}
                With your consent, we may provide aggregated, anonymized data to
                third-party providers to enhance your experience of Neuromonics
                and the third-party product you are using. This data does not
                identify you personally.
              </p>
              <p>
                <strong className="text-gray-900">Legal Requirements:</strong>{" "}
                We may disclose your information when required by law, such as
                to comply with a subpoena or similar legal process.
              </p>
              <p>
                <strong className="text-gray-900">Business Transfers:</strong>{" "}
                In connection with a merger, acquisition, or sale of all or a
                portion of our assets.
              </p>
            </div>
          </section>

          {/* Data Security */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                4
              </span>
              Data Security
            </h2>
            <div className="prose max-w-none text-gray-600 ml-11">
              <p>
                We implement appropriate technical and organizational measures
                to protect your personal information from unauthorized access,
                use, alteration, and disclosure.
              </p>
            </div>
          </section>

          {/* Your Rights */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                5
              </span>
              Your Rights
            </h2>
            <div className="prose max-w-none text-gray-600 ml-11">
              <p className="mb-4">You have the right to:</p>
              <ul className="space-y-2">
                {[
                  "Access the personal information we hold about you.",
                  "Request that we update or correct your information.",
                  "Request deletion of your personal information, subject to certain exceptions.",
                  "Opt-in and provide consent before we share your information for enhanced experience purposes",
                ].map((item, index) => (
                  <li key={index} className="flex items-start">
                    <span className="text-blue-600 mr-2">•</span>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          {/* Children's Privacy */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                6
              </span>
              Children's Privacy
            </h2>
            <div className="prose max-w-none text-gray-600 ml-11">
              <p>
                The App does not knowingly collect personal information from
                children under the age of 13. If we learn that we have collected
                personal information from a child under 13, we will take steps
                to delete such information from our files as soon as possible.
              </p>
            </div>
          </section>

          {/* Medical Disclaimer */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                7
              </span>
              Medical Disclaimer
            </h2>
            <div className="prose max-w-none text-gray-600 ml-11 space-y-4">
              <p className="font-semibold">
                The Neuromonics Tinnitus Treatment comprises BOTH (i) the
                Neuromonics App + (ii) the supervision & guidance of a
                Neuromonics-accredited audiological professional
              </p>
              <p>
                The services and applications provided by the Neuromonics App
                are designed to support, not replace, the relationship that
                exists between a patient and their Neuromonics-accredited
                audiological professional. It is crucial that the Neuromonics
                App is not used in isolation but rather used in conjunction with
                the strict supervision and guidance of a Neuromonics-accredited
                audiological professional. The application is intended to be a
                component of a comprehensive treatment plan tailored to the
                individual's needs.
              </p>
              <p className="font-semibold">Medical Advice</p>
              <p>
                Before making any medical decisions, including but not limited
                to the initiation, modification, or cessation of treatments
                related to tinnitus or any other condition, users should seek
                the advice of a medical doctor or qualified healthcare provider.
                The information and services provided through our application
                are not intended to be, and must not be taken as, professional
                medical advice or a substitute for such advice.
              </p>
            </div>
          </section>

          {/* Regulatory Approval */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                8
              </span>
              Regulatory Approval
            </h2>
            <div className="prose max-w-none text-gray-600 ml-11">
              <p>
                The Neuromonics Tinnitus Treatment combines: (i) the Neuromonics
                App that monitors and delivers spectrally-modified audio,
                specifically tailored to the individual's hearing and tinnitus
                profile, together with (ii) specialist evaluation, supervision,
                guidance and counselling of a Neuromonics-accredited
                audiological professional. The Neuromonics App embodies the
                audio component of the treatment, previously embodied in
                Neuromonics hardware devices (eg. the Neuromonics Oasis) which
                received United States Food & Drug Administration (FDA) 510(k)
                clearance{" "}
                <Link
                  to="https://www.accessdata.fda.gov/cdrh_docs/pdf4/K043274.pdf"
                  target="_blank"
                  className="text-blue-600 hover:text-blue-800"
                >
                  K043274
                </Link>{" "}
                on January 28, 2005.
              </p>
            </div>
          </section>

          {/* Changes to Privacy Policy */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                9
              </span>
              Changes to This Privacy Policy
            </h2>
            <div className="prose max-w-none text-gray-600 ml-11">
              <p>
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page and updating the "Effective Date" at the top.
              </p>
            </div>
          </section>

          {/* Contact Us */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                10
              </span>
              Contact Us
            </h2>
            <div className="prose max-w-none text-gray-600 ml-11">
              <p className="mb-4">
                If you have any questions about this Privacy Policy or our data
                practices, please contact us at:
              </p>
              <div className="space-y-2">
                <div className="flex flex-col sm:flex-row">
                  <span className="font-semibold w-24">Email:</span>
                  <span>info@neuromonics.com</span>
                </div>
                <div className="flex flex-col sm:flex-row">
                  <span className="font-semibold w-24">Address:</span>
                  <div>
                    <p>
                      SoundVida, Inc. (trading as Neuromonics)
                      <br />
                      26565 West Agoura Road, Suite 200
                      <br />
                      Calabasas CA 91302
                      <br />
                      United States of America
                    </p>
                  </div>
                </div>
              </div>
              <p className="mt-4">
                Your privacy is our priority, and we are committed to protecting
                your personal information while enhancing your experience with
                Neuromonics.
              </p>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
