import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/frontendComponents/header/Header";
import Footer from "../../../components/frontendComponents/footer/Footer";
import doctorImage from "../../../assets/common/img/doctor-image.png";
import { Link } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../constants/RouteConst";

function Neuromonics() {
  const scrollContainer = useRef(null);

  const providerSchema = {
    "@context": "https://schema.org",
    "@type": "MedicalSoftware",
    name: "Neuromonics Provider Platform",
    applicationCategory: "Healthcare Software",
    operatingSystem: "Web-based",
    offers: {
      "@type": "Offer",
      description: "Healthcare Provider Platform for Tinnitus Treatment",
    },
    audience: {
      "@type": "Audience",
      audienceType: "Healthcare Professionals",
    },
    healthcareConsideration: "Tinnitus Treatment Management",
    isAccessibleForFree: false,
    features: [
      "Remote Care Flexibility",
      "Lower Upfront Cost",
      "Accessible and Scalable",
      "Treatment Controls",
      "Analytics Dashboard",
      "Ongoing Support",
    ],
  };

  const features = [
    {
      id: 1,
      title: "Remote Care Flexibility",
      description: "Provide tinnitus care remotely",
    },
    {
      id: 2,
      title: "Lower Upfront Cost",
      description: "Cost-effective solution for practices",
    },
    {
      id: 3,
      title: "Accessible and Scalable",
      description: "Easily scale your tinnitus treatment services",
    },
    {
      id: 4,
      title: "Treatment Controls",
      description: "Full control over patient treatment plans",
    },
    {
      id: 5,
      title: "Analytics Dashboard",
      description: "Track patient progress and outcomes",
    },
    {
      id: 6,
      title: "Ongoing Support",
      description: "Continuous clinical and technical support",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Neuromonics for Healthcare Providers | Tinnitus Treatment Platform
        </title>
        <meta
          name="description"
          content="Empower your practice with Neuromonics' comprehensive tinnitus treatment platform. Deliver remote care, track patient progress, and grow your practice with our FDA-cleared solution."
        />
        <meta
          name="keywords"
          content="tinnitus treatment platform, healthcare provider platform, remote tinnitus care, tinnitus analytics, medical practice solution"
        />
        <script type="application/ld+json">
          {JSON.stringify(providerSchema)}
        </script>
        <link rel="canonical" href="https://neuromonics.com/providers" />
      </Helmet>
      <div className="frontendOuter" ref={scrollContainer}>
        <Header scrollContainer={scrollContainer} secondHeader={true} />
        {/* home page start */}
        <div className="mx-auto bg-primary">
          <div className="container mx-auto px-4 md:px-16 pt-32 pb-16">
            <div className="grid lg:grid-cols-2 gap-16 items-center">
              {/* Left Content */}
              <div className="space-y-8">
                <div className="inline-block">
                  <span className="bg-[#fffe7d] px-4 py-2 rounded-full text-sm font-semibold text-primary">
                    Exclusively for Healthcare Professionals
                  </span>
                </div>

                <div className="space-y-4">
                  <h1 className="text-white text-4xl md:text-5xl font-medium leading-tight">
                    We deliver the platform.
                    <div className="mt-6">You deliver the care.</div>
                  </h1>

                  <p className="text-white text-lg md:text-xl pt-8 max-w-xl">
                    Healthcare professionals rely on Neuromonics to deliver
                    tinnitus treatments every day.
                  </p>
                </div>

                <div className="space-y-4">
                  {features.map((feature) => (
                    <div key={feature.id} className="flex items-center gap-3">
                      <div className="w-6 h-6 rounded border-2 border-[#fffe7d] flex items-center justify-center flex-shrink-0">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-[#fffe7d]"
                        >
                          <path
                            d="M10 3L4.5 8.5L2 6"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <span className="text-white text-lg">
                        {feature.title}
                      </span>
                    </div>
                  ))}
                </div>

                <div className="flex flex-col sm:flex-row gap-4">
                  <Link
                    to={ADMIN_ROUTES.SIGNUP_URL}
                    className="inline-block text-center px-8 py-3 bg-secondary text-primary rounded-xl font-semibold hover:bg-gray-300 transition-colors"
                  >
                    Sign up now
                  </Link>
                </div>
              </div>

              {/* Right Image */}
              <div className="relative">
                <div className="bg-white rounded-xl max-w-[480px] mx-auto">
                  <img
                    src={doctorImage}
                    alt="Healthcare professional using Neuromonics platform for tinnitus treatment"
                    className="rounded-lg w-full h-auto"
                    width="480"
                    height="640"
                    loading="eager"
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
          {/* footer end */}
        </div>
      </div>
    </>
  );
}

export default Neuromonics;
