import { db, auth } from "./firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import axios from "axios";
import { BASE_URL } from "./fetch";
import { createSearchIndex } from "./utils/search";

const userProfiles = collection(db, "userProfiles");
const activationCodes = collection(db, "activationCodes");
// const allUsers = collection(db, "allUsers");
const template = collection(db, "templates");

// export const checkEmailExists = async (email) => {
//   const qu = query(userProfiles, where('emailAddress', '==', email));
//   const queryEmailSnapshot = await getDocs(qu);
//   return !queryEmailSnapshot.empty;
// };

export const checkEmailExists = async (email) => {
  return axios
    .get(`${BASE_URL}checkEmail?email=` + email)
    .then((response) => response?.data?.user)
    .catch((error) => error?.response?.data?.user);
};

export const checkActivationCodeExists = async (code) => {
  const activationCodeDoc = doc(activationCodes, code);
  const checkActivationCodeDoc = await getDoc(activationCodeDoc);
  return checkActivationCodeDoc.exists();
};

export const getAuthDetails = async (email) => {
  const q = query(userProfiles, where("emailAddress", "==", email));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    return null;
  }
  const docId = querySnapshot.docs[0].id;
  const docData = querySnapshot.docs[0].data();
  return { docId, docData };
};

export const getUserName = async (email) => {
  const q = query(userProfiles, where("emailAddress", "==", email));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    return null;
  }
  const docData = querySnapshot.docs[0].data();
  return docData.firstName + " " + docData.lastName;
};

/* Generate Random Code */
export const RandomCodeGenerator = () => {
  const randomNumber = Math.floor(Math.random() * 1000000);
  const formattedCode = randomNumber.toString().padStart(6, "0");
  return formattedCode;
};

export const getDocsWithPagination = async (
  collectionRef,
  pageNumber,
  pageSize
) => {
  const startIndex = (pageNumber - 1) * pageSize;
  const limit = pageSize;
  const query = collectionRef.limit(limit).startAt(startIndex);
  const querySnapshot = await getDocs(query);
  return querySnapshot;
};

/* to get template content  */
export const getTemplateContent = async (type) => {
  const qu = query(template, where("type", "==", type));
  const querySnapshot = await getDocs(qu);
  const templateData = querySnapshot.docs.map((doc) => doc.data());

  return templateData;
};

/* to create event logs in userEvents collection  */
export const createEvent = async (data) => {
  const userEventDocRef = doc(collection(db, "userEvents"), data.userId);
  const checkExistUserEvent = await getDoc(userEventDocRef);

  if (!checkExistUserEvent.exists()) {
    await setDoc(userEventDocRef, {});
  }
  const eventsCollectionRef = collection(userEventDocRef, "events");
  console.debug(auth);
  const loggedInUserId = auth.currentUser.uid;
  await firestoreTransaction(addDoc, eventsCollectionRef, {
    version: "1.0",
    date: serverTimestamp(),
    source: "web",
    userId: loggedInUserId,
    type: data.type,
    details: data.details,
  });
};

/* helper function to catch firestore transaction errors:
    firebaseFunc: A firebase function
    ...args: The firebase functions arguments */
export const firestoreTransaction = async (firebaseFunc, ...args) => {
  try {
    const funcCall = await firebaseFunc(...args);
    return funcCall;
  } catch (error) {
    const errorMessage = `Error in firestore transaction: ${error.message}`;
    console.error(errorMessage);
    throw errorMessage;
  }
};

/**
 * Creates search index for user profile data
 * @param {Object} userData User profile data
 * @returns {Object} Search index object
 */
export const createUserProfileSearchIndex = (userData) => {
  const searchableText = [
    userData.firstName,
    userData.lastName,
    userData.emailAddress,
    userData.qualifications,
    userData.state,
    userData.country,
  ]
    .filter(Boolean)
    .join(" ");

  return createSearchIndex(searchableText);
};

/**
 * Creates search index for patient/client profile data
 * @param {Object} patientData Patient profile data
 * @returns {Object} Search index object
 */
export const createPatientSearchIndex = (patientData) => {
  const searchableText = [
    patientData.firstName,
    patientData.lastName,
    patientData.emailAddress,
    patientData.state,
    patientData.country,
  ]
    .filter(Boolean)
    .join(" ");

  return createSearchIndex(searchableText);
};
