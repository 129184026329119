import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { DATABASECOLLECTION } from "../constants/DatabaseConst";
import { db } from "./firebase";
import { createUserSearchIndex, soundex } from "./searchUtils";

// Update search index for a user
export const updateUserSearchIndex = async (userId, userData) => {
  const userRef = doc(db, DATABASECOLLECTION.USERPROFILES, userId);
  const searchIndex = createUserSearchIndex(userData);

  await updateDoc(userRef, {
    searchIndex: searchIndex,
  });
};

// Search users with fuzzy matching
export const searchUsers = async (searchTerm, maxResults = 50) => {
  const usersRef = collection(db, DATABASECOLLECTION.USERPROFILES);

  const searchText = searchTerm
    .trim()
    .split(" ")
    .map((term) => soundex(term))
    .join(" ");

  const q = query(
    usersRef,
    orderBy(`searchIndex.${searchText}`),
    limit(maxResults)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const createSearchIndexForNewUser = async (userId, userData) => {
  await updateUserSearchIndex(userId, userData);
};
