import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import logo from "../../../assets/common/img/logo.png";
import logoBlack from "../../../assets/common/img/logo_back.png";
import HeaderOffcanvas from "../headerOffcanvas/HeaderOffcanvas";
import { Link } from "react-router-dom";
import { ADMIN_ROUTES, FRONTEND_ROUTES } from "../../../constants/RouteConst";
import { ModalBody, ModalHeader } from "react-bootstrap";
import ReactModal from "react-modal";
import { getAuth, signInAnonymously, signOut } from "firebase/auth";
import { checkEmailExists, getTemplateContent } from "../../../common/helper";
import { EventType } from "../../../constants/EventConst";
import { getData, sendEmail } from "../../../common/fetch";
import Alert from "../../../components/alert/Alert";
import { createEvent } from "../../../common/helper";
import { TEMPPLATETYPE } from "../../../constants/TemplateTypeConst";
import Loader from "../../../pages/extra/Loader";

const landingUrls = ["/", "/neuromonics", "/book-demo", "/appointment-confirm"];

const colorPalette = {
  primaryBackground: "bg-primary",
  secondaryBackground: "bg-secondary",
  primaryText: "text-white hover:text-gray-200",
  secondaryText: "text-[#142F68] hover:text-[#506999]",
  primaryBorder: "border-secondary text-warning hover:bg-[#476879]",
  secondaryBorder:
    "border-secondary text-warning hover:bg-[#476879]  bg-primary",
};

const Header = ({ scrollContainer, secondHeader, color = "primary" }) => {
  const location = useLocation();
  const [, setScrolled] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [show, setShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [authHomeChecked, setAuthHomeChecked] = useState(false);
  const [isColorReversed, setIsColorReversed] = useState(false);

  // To handle Enquire Form
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [message, setMessage] = useState("");

  const handleClick = () => {
    setIsActive(!isActive);
  };

  /* Enquiry Modal */
  const openModal = () => {
    setSuccessMsg("");
    setErrorMsg("");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  /* Enquiry Modal */

  useEffect(() => {
    const container = scrollContainer.current;

    const handleScroll = () => {
      if (scrollContainer.current) {
        const scrollY = scrollContainer.current.scrollTop;
        if (scrollY > 80) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      }
    };

    if (scrollContainer.current) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollContainer]);

  useEffect(() => {
    console.log(location.pathname);
    if (landingUrls.includes(location.pathname)) {
      setIsColorReversed(true);
    } else {
      setIsColorReversed(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const isAuthenticated =
      localStorage.getItem("role") !== "" &&
      localStorage.getItem("role") !== null;
    setAuthHomeChecked(isAuthenticated);
  }, [authHomeChecked]);

  // Handle the enquire by Unauthorized or Authorized user from Public web
  const handleEnquire = async () => {
    try {
      setErrorMsg("");
      setSuccessMsg("");
      setIsLoading(true);
      const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
      };

      const mandatoryFields = [firstName, email, message];

      for (const field of mandatoryFields) {
        if (!field) {
          setIsLoading(false);
          setErrorMsg(
            "Please complete all mandatory fields (indicated by an asterisk)."
          );
          return;
        }
      }

      if (!isValidEmail(email)) {
        setIsLoading(false);
        setErrorMsg(
          "Invalid email format. Please enter a valid email address."
        );
        return;
      }

      const auth = getAuth();
      const checkClientEmailExists = await checkEmailExists(email);
      const userId = checkClientEmailExists
        ? (await getData(email)).uid
        : (await signInAnonymously(auth)).user.uid;

      const data = {
        userId: userId,
        type: EventType.ENQUIRY,
        details: {
          firstName: firstName,
          emailAddress: email,
          state: state,
          country: country,
          message: message,
        },
      };

      await createEvent(data); //create event for enquiry data by unauthorized user from public website

      const adminTemplate = await getTemplateContent(
        TEMPPLATETYPE.WEBSITEENQUIRYADMIN
      );
      const templateAdminContent = adminTemplate[0].content;
      const updatedEmailAdminContent = templateAdminContent
        .replace(/%%FirstName%%/g, firstName)
        .replace(/%%Email%%/g, email)
        .replace(/%%State%%/g, state)
        .replace(/%%Country%%/g, country)
        .replace(/%%Message%%/g, message);

      const clientTemplate = await getTemplateContent(
        TEMPPLATETYPE.WEBSITEENQUIRYCLIENT
      );
      const templateClientContent = clientTemplate[0].content;
      const updatedEmailClientContent = templateClientContent.replace(
        /%%FirstName%%/g,
        firstName
      );

      await sendEmail(
        adminTemplate[0].recipient,
        adminTemplate[0].subject,
        updatedEmailAdminContent,
        adminTemplate[0].bcc
      );
      await sendEmail(
        email,
        clientTemplate[0].subject,
        updatedEmailClientContent,
        adminTemplate[0].bcc
      );

      setErrorMsg("");
      // setFirstName('');
      // setEmail('');
      // setState('');
      // setCountry('');
      // setMessage('');
      setIsLoading(false);
      setSuccessMsg("Enquiry sent successfully!");
      await signOut(auth);
    } catch (error) {
      setIsLoading(false);
      if (error.code === "auth/email-already-in-use") {
        setErrorMsg(
          "This email is already in use. Please use a different email."
        );
      } else {
        console.log(error);
        setErrorMsg("An error occurred. Please try again later.");
      }
    }
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <div
        className={`w-full fixed top-0 z-50 ${
          isColorReversed
            ? colorPalette.primaryBackground
            : colorPalette.secondaryBackground
        }`}
      >
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center justify-between px-4 py-4">
            {/* Logo */}
            <div className="flex-shrink-0">
              <Link to={FRONTEND_ROUTES.HOME_PAGE}>
                <img
                  src={isColorReversed ? logo : logoBlack}
                  alt="Logo"
                  className="h-16"
                />
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden lg:flex items-center space-x-8">
              <nav>
                <ul className="flex items-center space-x-6">
                  <li>
                    <Link
                      to={FRONTEND_ROUTES.TINNITUS}
                      // className="text-white hover:text-gray-200 transition-colors font-semibold"
                      className={`${
                        isColorReversed
                          ? colorPalette.primaryText
                          : colorPalette.secondaryText
                      } transition-colors font-semibold`}
                    >
                      What is Tinnitus?
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={FRONTEND_ROUTES.NEUROMONICS}
                      className={`${
                        isColorReversed
                          ? colorPalette.primaryText
                          : colorPalette.secondaryText
                      } transition-colors font-semibold`}
                    >
                      Healthcare Professionals
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={FRONTEND_ROUTES.BLOGS}
                      className={`${
                        isColorReversed
                          ? colorPalette.primaryText
                          : colorPalette.secondaryText
                      } transition-colors font-semibold`}
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={FRONTEND_ROUTES.JOURNALS}
                      className={`${
                        isColorReversed
                          ? colorPalette.primaryText
                          : colorPalette.secondaryText
                      } transition-colors font-semibold`}
                    >
                      Resources
                    </Link>
                  </li>

                  {authHomeChecked ? (
                    <li>
                      <Link
                        to={ADMIN_ROUTES.LOGIN_URL}
                        className={`${
                          isColorReversed
                            ? colorPalette.primaryText
                            : colorPalette.secondaryText
                        } transition-colors font-semibold`}
                      >
                        My Account
                      </Link>
                    </li>
                  ) : (
                    <li
                      onClick={handleClick}
                      className={isActive ? "active" : ""}
                    >
                      <Link
                        to={ADMIN_ROUTES.LOGIN_URL}
                        className={`${
                          isColorReversed
                            ? colorPalette.primaryText
                            : colorPalette.secondaryText
                        } transition-colors font-semibold`}
                      >
                        Sign In
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link
                      to="/start-your-journey"
                      // className="px-3 md:px-4 py-2 border border-secondary text-warning rounded-xl hover:bg-[#476879] transition-colors no-underline font-semibold"
                      className={`px-3 md:px-4 py-2 border ${
                        isColorReversed
                          ? colorPalette.primaryBorder
                          : colorPalette.secondaryBorder
                      } rounded-xl transition-colors no-underline font-semibold`}
                    >
                      Start Your Journey
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>

            {/* Mobile Navigation */}
            <div className="flex lg:hidden items-center space-x-4">
              <button
                onClick={openModal}
                // className="px-3 py-2 text-white hover:text-gray-200 transition-colors"
                className={`px-3 py-2 ${
                  isColorReversed
                    ? colorPalette.primaryText
                    : colorPalette.secondaryText
                } transition-colors`}
              >
                Start Your Journey
              </button>

              <button
                onClick={handleShow}
                // className="text-white hover:text-gray-200 transition-colors"
                className={`${
                  isColorReversed
                    ? colorPalette.primaryText
                    : colorPalette.secondaryText
                } transition-colors`}
              >
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3 4H21V6H3V4ZM9 11H21V13H9V11ZM3 18H21V20H3V18Z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Your Modal"
        className="custom-modal"
        style={{
          content: {
            // width: '50%',
            height: "90%",
            margin: "auto",
            left: "356px",
            marginTop: "20px",

            backgroundColor: "#ffffff",
            border: "1px solid",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          },
          overlay: {
            backgroundColor: "rgb(255 255 255 / 88%)",
            zIndex: "99999",
          },
        }}
      >
        <ModalHeader
          style={{
            padding: "10px",
            background: "#517991",
            color: "#fff",
            position: "sticky",
            top: "0",
            zIndex: "100000",
          }}
          className="row gx-0 align-items-center justify-content-center"
        >
          <div className="col-10">
            <div className="enqnumLogo">
              <img src={logo} alt="" />
            </div>
          </div>
          <div className="col-2">
            <Link
              className="text-light d-flex justify-content-end"
              onClick={closeModal}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                style={{ height: "24px", width: "24px" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"></path>
              </svg>
            </Link>
          </div>
        </ModalHeader>
        <ModalBody
          style={{
            overflow: "auto",
            maxHeight: "73%%",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="col-12">
            <div className="enquiretxt mb-2">Enquiry Form</div>
            {/* <p className="enqpara">
                            Please fill out the form below to send your inquiry.
                        </p> */}
          </div>
          <div className="col-12 flex-fill">
            {errorMsg && <Alert text={errorMsg} type="error" />}
            {successMsg && <Alert text={successMsg} type="success" />}
            {isLoading && <Loader isEnquieySpinner={true} />}
            {!isLoading && (
              <div className="MainForm">
                <div className="row">
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control shadow-none border formcontrolCustom modalRound"
                        id="firstName"
                        name="firstName"
                        placeholder=""
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <label htmlFor="firstName" className="formControlLable">
                        <div className="labelTxt">Name *</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control shadow-none border formcontrolCustom modalRound"
                        id="email"
                        name="email"
                        placeholder=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label htmlFor="email" className="formControlLable">
                        <div className="labelTxt">Email *</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control shadow-none border formcontrolCustom modalRound"
                        id="state"
                        name="state"
                        placeholder=""
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                      <label htmlFor="state" className="formControlLable">
                        <div className="labelTxt">State</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control shadow-none border formcontrolCustom modalRound"
                        id="country"
                        name="country"
                        placeholder=""
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                      <label htmlFor="country" className="formControlLable">
                        <div className="labelTxt">Country</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating mb-3 ">
                      <textarea
                        className="form-control formcontroltxtCustom shadow-none maptextarea"
                        rows="3"
                        placeholder="Leave a comment here"
                        name="message"
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                      <label htmlFor="message">
                        <div className="labelTxt">Message*</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-6 mx-auto">
                    <div className="signInButton mt-3 text-center">
                      <button className="signinBtn" onClick={handleEnquire}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
      </ReactModal>
      <HeaderOffcanvas show={show} setShow={setShow} />
    </>
  );
};

export default Header;
