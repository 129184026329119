import React, { useState } from "react";
import AdimnSidebar from "../../components/adminSidebar/AdminSidebar";
import AdminHeader from "../../components/adminHeader/AdminHeader";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, setDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../common/firebase";
import { ROLES } from "../../constants/RoleConst";
import Alert from "../../components/alert/Alert";
import { sendEmail, setRoleData } from "../../common/fetch";
import { DATABASECOLLECTION } from "../../constants/DatabaseConst";
import { EventType } from "../../constants/EventConst";
import { TEMPPLATETYPE } from "../../constants/TemplateTypeConst";
import { getTemplateContent } from "../../common/helper";
import { createEvent, createUserProfileSearchIndex } from "../../common/helper";

const AddAdmin = () => {
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [qualifications, setQualifications] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");

  const handleRegistration = async () => {
    try {
      setSuccessMsg("");
      setErrorMsg("");

      const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
      };

      const validationRules = [
        {
          field: firstName,
          message: "The first name field must not be left blank.",
        },
        {
          field: lastName,
          message: "The last name field must not be left blank.",
        },
        {
          field: email,
          message: "The email address field must not be left blank.",
        },
        {
          field: password,
          message: "The password field must not be left blank",
        },
        { field: phone, message: "The phone field must not be left blank." },
        { field: state, message: "The state field must not be left blank." },
        {
          field: country,
          message: "The country field must not be left blank.",
        },
      ];

      for (const rule of validationRules) {
        if (!rule.field) {
          setErrorMsg(rule.message);
          return false;
        }
      }
      if (!isValidEmail(email)) {
        setErrorMsg("Entered invalid email address");
        return false;
      }
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const userId = userCredential.user.uid;
      const defaultRole = ROLES.ADMIN;

      if (userCredential && userCredential.user) {
        try {
          await auth.signOut();
          await setRoleData(userId, defaultRole);
          const userProfileDocRef = doc(
            collection(db, DATABASECOLLECTION.USERPROFILES),
            userId
          );
          const userData = {
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            emailAddress: email,
            qualifications: qualifications,
            state: state,
            country: country,
            message: message,
            isActive: true,
            searchIndex: createUserProfileSearchIndex({
              firstName,
              lastName,
              emailAddress: email,
              qualifications,
              state,
              country,
            }),
          };
          await setDoc(userProfileDocRef, userData);

          const stateRef = collection(
            userProfileDocRef,
            DATABASECOLLECTION.STATE
          );
          const currentDocRef = doc(stateRef, DATABASECOLLECTION.CURRENT);
          const currentDocSnap = await getDoc(currentDocRef);

          if (!currentDocSnap.exists()) {
            await setDoc(currentDocRef, {
              completedHearingTest: false,
              completedTRQ: false,
              connectedClinician: false,
              onboardComplete: true,
            });
          }
          const data = {
            userId: userId,
            type: EventType.ADDADMIN,
            details: {
              firstName: firstName,
              lastName: lastName,
              phone: phone,
              emailAddress: email,
              qualifications: qualifications,
              state: state,
              country: country,
              message: message,
            },
          };
          await createEvent(data); //create event when new admin added
          const clientTemplate = await getTemplateContent(
            TEMPPLATETYPE.NEWADMINADDEDCLIENT
          );
          const templateContent = clientTemplate[0].content;
          const updatedEmailContent = templateContent
            .replace(/%%FirstName%%/g, firstName)
            .replace(/%%LastName%%/g, lastName)
            .replace(/%%Email%%/g, email)
            .replace(/%%Password%%/g, password);
          await sendEmail(
            email,
            updatedEmailContent[0].subject,
            updatedEmailContent,
            updatedEmailContent[0].bcc
          );

          setSuccessMsg(
            "Admin registered successfully and credentials have been sent to the email."
          );
        } catch (error) {
          if (error.code === "auth/email-already-in-use") {
            setErrorMsg(
              "This email is already in use. Please use a different email."
            );
          } else {
            setErrorMsg(
              "An error occurred during registration. Please try again."
            );
          }
        }
      }
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setErrorMsg(
          "This email is already in use. Please use a different email."
        );
      } else {
        console.log(error);
        setErrorMsg("An error occurred during registration. Please try again.");
      }
    }
  };
  return (
    <>
      <div className="dashboardWrapper">
        <AdimnSidebar />
        <div className="adminDashBoard">
          <AdminHeader />
          <div className="adminBody">
            <div className="registerUser commonCard">
              <div className="row">
                <div className="col-12">
                  <div className="card-header ">
                    <div className="SectionHeadingMain">Add Admin</div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="MainForm">
                    {errorMsg && <Alert text={errorMsg} type="error" />}
                    {successMsg && <Alert text={successMsg} type="success" />}
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control shadow-none border formcontrolCustom"
                            id="firstName"
                            name="firstName"
                            placeholder=""
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                          <label
                            htmlFor="firstName"
                            className="formControlLable"
                          >
                            <div className="labelTxt">First Name</div>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control shadow-none border formcontrolCustom"
                            id="lastname"
                            name="lastname"
                            placeholder=""
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          <label
                            htmlFor="lastname"
                            className="formControlLable"
                          >
                            <div className="labelTxt">Last Name</div>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control shadow-none border formcontrolCustom"
                            id="email"
                            name="email"
                            placeholder="name@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <label htmlFor="email" className="formControlLable">
                            <div className="labelTxt">Email</div>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <input
                            type="password"
                            className="form-control shadow-none border formcontrolCustom"
                            id="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <label
                            htmlFor="password"
                            className="formControlLable"
                          >
                            <div className="labelTxt">Password</div>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <input
                            type="tel"
                            className="form-control shadow-none border formcontrolCustom"
                            id="phone"
                            name="phone"
                            placeholder=""
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                          <label htmlFor="phone" className="formControlLable">
                            <div className="labelTxt">Phone Number</div>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control shadow-none border formcontrolCustom"
                            id="qualifications"
                            name="qualifications"
                            placeholder=""
                            value={qualifications}
                            onChange={(e) => setQualifications(e.target.value)}
                          />
                          <label
                            htmlFor="qualifications"
                            className="formControlLable"
                          >
                            <div className="labelTxt">Qualifications</div>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control shadow-none border formcontrolCustom"
                            id="state"
                            name="state"
                            placeholder=""
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          />
                          <label htmlFor="state" className="formControlLable">
                            <div className="labelTxt">State/province</div>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control shadow-none border formcontrolCustom"
                            id="country"
                            name="country"
                            placeholder=""
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          />
                          <label htmlFor="country" className="formControlLable">
                            <div className="labelTxt">Country</div>
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating mb-3 ">
                          <textarea
                            className="form-control formcontroltxtCustom border shadow-none"
                            rows="3"
                            placeholder="Leave a comment here"
                            name="message"
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                          <label htmlFor="message">
                            <div className="labelTxt">Message (Optional)</div>
                          </label>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="signInButton mt-3 mt-md-4">
                          <button
                            className="signinBtn"
                            onClick={handleRegistration}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdmin;
