import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { getAllClientList, getClinicanList } from "../../common/fetch";
import { db } from "../../common/firebase";
import { searchUsers } from "../../common/firebaseSearch";
import AdminHeader from "../../components/adminHeader/AdminHeader";
import AdimnSidebar from "../../components/adminSidebar/AdminSidebar";
import { SuccessBadge, WarningBadge } from "../../components/badges/Badges";
import { DATABASECOLLECTION } from "../../constants/DatabaseConst";
import { ROLES } from "../../constants/RoleConst";
import { ADMIN_ROUTES } from "../../constants/RouteConst";
import Loader from "../extra/Loader";

const AllPatientListing = () => {
  const [data, setData] = useState();
  const [finalData, setFinalData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  // const [fullName, setFullName] = useState("");
  // const [email, setEmail] = useState("");
  // const [status, setStatus] = useState("");
  const [clinicianList, setClinicianList] = useState([]);
  // const [selectedClinician, setSelectedClinician] = useState("");
  const role = localStorage.getItem("role");
  const [clinicianObj, setClinicianObj] = useState({});
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    status: "",
    clinician: "",
  });

  useEffect(() => {
    const getclinicianList = async () => {
      try {
        const usersCollection = collection(db, DATABASECOLLECTION.USERPROFILES);
        console.log("users ------------> before");

        // Get initial users list
        const users = await getClinicanList();
        console.log("users ------------> after", users);

        // Use Promise.all to fetch all user details in parallel
        const clinicianL = await Promise.all(
          users.map(async (user) => {
            const userDocRef = doc(usersCollection, user.uid);
            const [userDocumentSnapshot, stateSnapshot] = await Promise.all([
              getDoc(userDocRef),
              getDoc(
                doc(
                  collection(userDocRef, DATABASECOLLECTION.STATE),
                  DATABASECOLLECTION.CURRENT
                )
              ),
            ]);

            if (userDocumentSnapshot.exists()) {
              const userDetails = userDocumentSnapshot.data();
              userDetails.creationTime = user.metadata.creationTime;
              userDetails.uid = user.uid;
              userDetails.state = stateSnapshot.exists()
                ? stateSnapshot.data()
                : null;
              return userDetails;
            }
            return null;
          })
        );

        // Filter out any null values and sort
        const sorted = clinicianL
          .filter(Boolean)
          .sort((a, b) =>
            `${a?.firstName || ""} ${a?.lastName || ""}`.localeCompare(
              `${b?.firstName || ""} ${b?.lastName || ""}`
            )
          );

        console.log("Sorted: ----------------> ", sorted);
        setClinicianList(sorted);
      } catch (error) {
        console.error("Error fetching clinician list:", error);
        // Handle error appropriately
      }
    };

    getclinicianList();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const clientRef = await getAllClientList();
        const promises = clientRef?.map(async (client) => {
          const usersCollection = collection(
            db,
            DATABASECOLLECTION.USERPROFILES
          );
          const userDocRef = doc(usersCollection, client.uid);
          const userDocumentSnapshot = await getDoc(userDocRef);
          if (userDocumentSnapshot.exists()) {
            const clientUIDData = userDocumentSnapshot.data();
            clientUIDData.uid = userDocumentSnapshot.id;

            const userSettingsRef = collection(
              db,
              `${DATABASECOLLECTION.USERPROFILES}/${client.uid}/${DATABASECOLLECTION.OTHERSETTINGS}`
            );
            const userSettingsSnapshot = await getDocs(userSettingsRef);

            if (!userSettingsSnapshot.empty) {
              const clientUIDsReferences = userSettingsSnapshot.docs[0];
              const clientUIDsDataU = clientUIDsReferences.data() || [];
              // console.log("clientUIDsDataU",clientUIDsDataU.clinicianUID.path);
              clientUIDData.clinicianUID = clientUIDsDataU.clinicianUID.path;
              clientUIDData.activationCode = clientUIDsDataU.activationCode;

              const activationCodeDoc = doc(
                collection(db, DATABASECOLLECTION.ACTIVATIONCODES),
                clientUIDsDataU.activationCode
              );
              const activationDocumentSnapshot = await getDoc(
                activationCodeDoc
              );
              const activationSnapshot = activationDocumentSnapshot.data();
              clientUIDData.isUsed = activationSnapshot?.isUsed;
            }
            return clientUIDData;
          }
          return null;
        });

        const resolvedPromises = await Promise.all(promises);
        const resultEvent = resolvedPromises.filter(
          (client) => client !== null
        );

        console.log("resultEvent", resultEvent);

        setData(resultEvent);
        setFinalData(resultEvent);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching documents:", error.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (clinicianList?.length) {
      const obj = clinicianList.reduce((acc, element) => {
        acc[element?.uid] = `${element?.firstName} ${element?.lastName}`;
        return acc;
      }, {});
      setClinicianObj(obj);
    }
  }, [clinicianList]);

  const resetFilters = () => {
    setFilters({
      name: "",
      email: "",
      status: "",
      clinician: "",
    });
    setData(finalData); // Reset to original data
  };

  useEffect(() => {
    const filterData = async () => {
      if (!finalData) return;

      try {
        let filteredData = [...finalData];

        if (filters.name.trim()) {
          const searchResults = await searchUsers(filters.name);
          const searchResultIds = new Set(searchResults.map((r) => r.id));
          filteredData = filteredData.filter((item) =>
            searchResultIds.has(item.uid)
          );
        }

        // Apply email filter
        if (filters.email.trim()) {
          filteredData = filteredData.filter(({ emailAddress }) =>
            emailAddress?.toLowerCase().includes(filters.email.toLowerCase())
          );
        }

        // Apply status filter
        if (filters.status) {
          const isStatusTrue = filters.status === "true";
          filteredData = filteredData.filter(
            ({ isUsed }) => isUsed === isStatusTrue
          );
        }

        // Apply clinician filter
        if (filters.clinician) {
          filteredData = filteredData.filter(
            ({ clinicianUID }) => clinicianUID === filters.clinician
          );
        }

        setData(filteredData);
      } catch (error) {
        console.error("Error filtering data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    filterData();
  }, [filters, finalData]);

  const columns = [
    {
      name: "Full Name",
      selector: (row) => row.firstName + " " + row.lastName,
      sortable: true,
    },
    {
      name: "Email ID",
      selector: (row) => row.emailAddress,
      sortable: true,
    },
    {
      name: "Activation Code",
      selector: (row) => (row.isUsed ? "-" : row.activationCode),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return row.isUsed ? (
          <SuccessBadge text="Activated" />
        ) : (
          <WarningBadge text="Pending" />
        );
      },
      sortable: true,
    },
    {
      name: "Clinician",
      selector: (row) => {
        let clinicianId = row?.clinicianUID
          ? row?.clinicianUID.split("/")?.[1]
          : "";
        return clinicianObj?.[clinicianId];
      },
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => <CustomActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const CustomActionsCell = ({ row }) => {
    const patient = row;
    const updateURL = `${ADMIN_ROUTES.MANAGE_CLIENT_URL}/${patient.uid}`;
    return (
      row.isUsed && (
        <div className="col-auto">
          <Link to={updateURL} className="text-decoration-none">
            <button
              type="button"
              button="true"
              className="editBtn btn btn-outline-dark"
            >
              <div className="editPencilImg d-flex align-items-center gap-1">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  style={{ height: "14px", width: "14px" }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M464 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zM128 120c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm288-136v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12z"></path>
                </svg>
                Manage
              </div>
            </button>
          </Link>
        </div>
      )
    );
  };

  return (
    <>
      <div className="dashboardWrapper">
        <AdimnSidebar />
        <div className="adminDashBoard">
          <AdminHeader />
          <div className="adminBody">
            <div className="row">
              <div className="col-12">
                <div className="commonCard">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="SectionHeadingMain">
                          Manage Patients
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {isLoading && <Loader />}
                    {!isLoading && (
                      <>
                        <div className="table-filters mb-3">
                          <div className="row g-2 align-items-center">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control form-control-sm filter-input"
                                placeholder="Filter by name"
                                value={filters.name}
                                onChange={(e) =>
                                  setFilters((prev) => ({
                                    ...prev,
                                    name: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            <div className="col">
                              <input
                                type="text"
                                className="form-control form-control-sm filter-input"
                                placeholder="Filter by email"
                                value={filters.email}
                                onChange={(e) =>
                                  setFilters((prev) => ({
                                    ...prev,
                                    email: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            <div className="col">
                              <select
                                className="form-select form-select-sm filter-input"
                                value={filters.status}
                                onChange={(e) =>
                                  setFilters((prev) => ({
                                    ...prev,
                                    status: e.target.value,
                                  }))
                                }
                              >
                                <option value="">All Status</option>
                                <option value="false">Pending</option>
                                <option value="true">Activated</option>
                              </select>
                            </div>
                            {role === ROLES.ADMIN && (
                              <div className="col">
                                <select
                                  className="form-select form-select-sm filter-input"
                                  value={filters.clinician}
                                  onChange={(e) =>
                                    setFilters((prev) => ({
                                      ...prev,
                                      clinician: e.target.value,
                                    }))
                                  }
                                >
                                  <option value="">All Clinicians</option>
                                  {clinicianList.map((item) => (
                                    <option
                                      key={item.uid}
                                      value={`${DATABASECOLLECTION.USERPROFILES}/${item.uid}`}
                                    >
                                      {`${item.firstName} ${item.lastName} (${item.emailAddress})`}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                            <div className="col-auto">
                              <button
                                className="btn btn-outline-secondary btn-sm filter-reset-btn"
                                onClick={resetFilters}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="managepatientsTable">
                          {data.length === 0 ? (
                            <>
                              <div className="cfvlby">
                                {columns.map((column) => (
                                  <div
                                    key={column.name}
                                    className="sc-crrtmM WubHI"
                                    style={{ paddingLeft: "36px" }}
                                  >
                                    <span className="sc-dQpIV ofEne">
                                      {column.name}
                                    </span>
                                  </div>
                                ))}
                              </div>
                              <p className="text-center py-2">
                                There are no records to display
                              </p>
                            </>
                          ) : (
                            <DataTable
                              fixedHeader
                              fixedHeaderScrollHeight="700px"
                              highlightOnHover
                              noContextMenu
                              pagination
                              responsive
                              subHeaderAlign="right"
                              subHeaderWrap
                              columns={columns}
                              data={data}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllPatientListing;
