import React, { useRef } from "react";
import Header from "../../../components/frontendComponents/header/Header";
import Footer from "../../../components/frontendComponents/footer/Footer";

const TermsCondition = () => {
  const scrollContainer = useRef(null);
  return (
    <div className="" ref={scrollContainer}>
      <Header scrollContainer={scrollContainer} secondHeader={true} />
      <div className="container mx-auto px-8 pt-32 pb-16 sm:px-10 lg:px-16">
        <div className="bg-white p-6 mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Neuromonics Terms & Condition
          </h1>
          <p className="text-sm text-gray-500 mb-4">
            Effective Date: January 1, 2024
          </p>
          <p className="text-gray-600 leading-relaxed">
            This Privacy Policy outlines how Neuromonics ("we", "us", or "our")
            collects, uses, discloses, and safeguards your information when you
            use our application (the "App").
          </p>
        </div>

        <div className="bg-white p-6 space-y-8">
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                1
              </span>
              Acceptance of Terms
            </h2>
            <div className="prose max-w-none text-gray-600">
              <p className="mb-4 ml-8">
                These Terms and Conditions ("Terms") govern your access to and
                use of the Neuromonics App (the "App") and any related products
                and services (collectively, the "Products and Services"). The
                term "Device" refers to the device used to access the Products
                or Services, including but not limited to computers,
                smartphones, and tablets. The term "you" refers to the user of
                the Products or Services. <br /> <br /> By creating an account,
                downloading, or using the App, you confirm that you have read,
                understood, and agree to be bound by these Terms and our Privacy
                Policy.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                2
              </span>
              Company Information
            </h2>
            <div className="prose max-w-none text-gray-600">
              <p className="mb-4 ml-8">
                Neuromonics, Inc., owned by SoundVida, Inc. ("Neuromonics,"
                "SoundVida," "us," or "we"), provides content and products via
                www.neuromonics.com (the “Website”), our applications (the
                “Apps”), or other delivery methods (collectively, the
                “Product”). SoundVida, Inc. is the owner of the Neuromonics App,
                the Neuromonics trademark, patents, and all intellectual
                property associated with Neuromonics.
              </p>
              <p className="mb-4 ml-8">
                Neuromonics, in partnership with its affiliated medical
                providers and partners, may deliver audiology services, coaching
                services, psychotherapy services (i.e., therapy), and psychiatry
                services (collectively, the “Services”) using the Product or via
                other delivery methods, as applicable. For purposes of clarity,
                the Services are provided by licensed clinicians and coaches
                employed by or contracted with our affiliated medical providers
                (the “Providers”). Neuromonics is not a provider of healthcare
                or mental health services; rather, Neuromonics offers a
                technology platform through which the Providers provide
                telehealth services.
              </p>
              <p>
                <b>Note:</b> Throughout these Terms, any reference to
                "Neuromonics" includes SoundVida, Inc., the owner of the
                Neuromonics App, trademark, patents, and all intellectual
                property.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                3
              </span>
              Privacy and Personal Information
            </h2>
            <div className="prose max-w-none text-gray-600">
              <p className="mb-4 ml-8">
                Protecting your personal information is extremely important to
                us. Information about our privacy practices can be found in our
                Privacy Policy. By using the Products and Services, you
                acknowledge that your use is also subject to our Privacy Policy.
                You further acknowledge that your use may require the collection
                of your health information, which may be considered sensitive
                under local laws such as the GDPR or certain U.S. state laws
                like Washington’s My Health My Data Act, Connecticut’s Data
                Privacy Act, and Nevada’s SB 370. We encourage you to review
                both our Privacy Policy and any applicable supplements to learn
                more about our privacy practices and commitments
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                4
              </span>
              Medical Emergency Disclaimer
            </h2>
            <div className="prose max-w-none text-gray-600">
              <p className="mb-4 ml-8">
                <b>IMPORTANT:</b> The Products and Services are not intended for
                use in medical emergencies. If you are in the United States and
                are experiencing thoughts of suicide or self-harm, please call
                or text 988, the Suicide and Crisis Lifeline. If you are
                experiencing a medical or mental health emergency, please call
                911 or go to the nearest emergency room. Do not attempt to
                access emergency care through the Products and Services. If you
                are located outside of the United States, please contact your
                local emergency services.
              </p>

              <h6 className="mb-4 ml-8 font-bold">Breathing Exercises:</h6>
              <p className="mb-4 ml-8">
                There have been rare reports that people with certain mental
                health conditions such as anxiety and depression have
                experienced worsening conditions in conjunction with intensive
                breathing practice. People with existing mental health
                conditions should speak with their healthcare providers before
                starting a breathing practice.
              </p>
              <p className="mb-4 ml-8">
                We assume no responsibility for injuries suffered while
                practicing these techniques, and SoundVida, Inc. and Neuromonics
                shall not be held liable for any damages, circumstances,
                conditions, or injuries that may occur, directly or indirectly,
                from engaging in any activities or ideas presented in any
                application made by SoundVida, Inc. and Neuromonics. By using
                the Neuromonics App, you accept all responsibility for your
                physical and mental health and any resultant injury or mishap
                that may affect your well-being in any way.
              </p>
              <p className="mb-4 ml-8">
                Do not use the breathing exercises while driving, in water, or
                performing other tasks that require attention and concentration.
                You understand that you are solely responsible for your use of
                the Services. We assume no responsibility for injuries suffered
                while practicing the techniques presented in the Services.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                5
              </span>
              Arbitration Notice
            </h2>
            <div className="prose max-w-none text-gray-600">
              <p className="mb-4 ml-8">
                Please note that these Terms contain an arbitration clause (see
                Section 14). Except for certain types of disputes mentioned in
                the arbitration clause, you and Neuromonics (including
                SoundVida, Inc.) agree that disputes relating to these Terms or
                your use of the Products and Services will be resolved by
                mandatory binding arbitration, and you waive any right to
                participate in a class action lawsuit or class-wide arbitration.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                6
              </span>
              What You're Signing Up For
            </h2>

            {/* Section 6.1 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">6.1</span>
                Products and Services
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                Products include, without limitation, tinnitus treatment,
                breathing exercises, meditation, and mindfulness content aimed
                at tinnitus habituation, stress reduction, sleep improvement.
                Services include, without limitation, mental health support
                through audiology, coaching, therapy, and psychiatry. If you
                elect to access and receive the Services, your care may be
                provided by audiologists, otolaryngologists (ENT specialists),
                mental health coaches, and licensed healthcare providers.
              </p>
            </div>

            {/* Section 6.2 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">6.2</span>
                General Rules for Use or Access
              </h3>

              {/* Alphabetical list */}
              <div className="ml-6 space-y-3">
                <div className="flex">
                  <span className="text-blue-600 w-6">(a)</span>
                  <p className="text-gray-600 flex-1">
                    <span className="font-medium">Acceptance of Terms:</span> By
                    ordering ("Order") any Products or Services, or otherwise
                    accessing or using them, you agree to be bound by these
                    Terms and all applicable laws, rules, regulations, judicial
                    orders, executive orders, or similar binding legal
                    instruments ("Applicable Law"). You may also be asked to
                    click "I agree" prior to your purchase or access.
                  </p>
                </div>

                <div className="flex">
                  <span className="text-blue-600 w-6">(b)</span>
                  <p className="text-gray-600 flex-1">
                    <span className="font-medium">Entire Agreement:</span> These
                    Terms and any applicable Order constitute the entire
                    agreement between you and us (Neuromonics, including
                    SoundVida, Inc.) for the supply of the Products and
                    Services, unless expressly stated otherwise. Additional
                    terms may apply for certain Products or Services; where such
                    terms are inconsistent with these Terms, the additional
                    terms will control.
                  </p>
                </div>

                <div className="flex">
                  <span className="text-blue-600 w-6">(c)</span>
                  <p className="text-gray-600 flex-1">
                    <span className="font-medium">
                      Accuracy of Information:
                    </span>{" "}
                    Please ensure that all details in these Terms and on any
                    applicable Order are complete and accurate before using or
                    purchasing the Products or Services. If you believe there is
                    a mistake, please contact us at help@neuromonics.com. We
                    only accept responsibility for statements and
                    representations made in writing by an authorized
                    representative of Neuromonics.
                  </p>
                </div>

                <div className="flex">
                  <span className="text-blue-600 w-6">(d)</span>
                  <p className="text-gray-600 flex-1">
                    <span className="font-medium">Technical Requirements:</span>{" "}
                    Use of the Products and Services requires a compatible
                    Device, internet access, and certain software. Maintenance
                    and security of your equipment may affect the performance of
                    the Products and Services. You are responsible for ensuring
                    your equipment meets the necessary requirements and for all
                    internet access charges.
                  </p>
                </div>
              </div>
            </div>

            {/* Section 6.3 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">6.3</span>
                Changes to Terms
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                Neuromonics reserves the right to modify these Terms or any of
                our policies at any time. If changes materially affect your
                rights or obligations, we may notify you and require you to
                accept the modified Terms to continue using the Products or
                Services. Your continued use signifies your acceptance of the
                updated Terms.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                7
              </span>
              Joining the Neuromonics Community
            </h2>

            {/* Section 7.1 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">7.1</span>
                Becoming a Member
              </h3>

              {/* Alphabetical list */}
              <div className="ml-6 space-y-3">
                <div className="flex">
                  <span className="text-blue-600 w-6">(a)</span>
                  <p className="text-gray-600 flex-1">
                    <span className="font-medium">Registration:</span> To access
                    the Products or Services, you must register as a user
                    ("Member") through our Website, Apps, or certain third-party
                    social networking services. You must provide accurate and
                    up-to-date personal information ("User Information").
                  </p>
                </div>

                <div className="flex items-start">
                  <span className="text-blue-600 w-6">(b)</span>
                  <div className="flex-1">
                    <p className="text-gray-600 mb-2">
                      <span className="font-medium">Eligibility:</span> By
                      registering, you warrant that:
                    </p>
                    <ul className="ml-6 space-y-2">
                      <li className="flex items-start">
                        <span className="text-blue-600 mr-2">•</span>
                        <span className="text-gray-600">
                          You are legally capable of entering into binding
                          contracts.
                        </span>
                      </li>
                      <li className="flex items-start">
                        <span className="text-blue-600 mr-2">•</span>
                        <span className="text-gray-600">
                          All User Information you submit is truthful, accurate,
                          and up-to-date.
                        </span>
                      </li>
                      <li className="flex items-start">
                        <span className="text-blue-600 mr-2">•</span>
                        <span className="text-gray-600">
                          You will maintain the accuracy of such information.
                        </span>
                      </li>
                      <li className="flex items-start">
                        <span className="text-blue-600 mr-2">•</span>
                        <span className="text-gray-600">
                          Your use does not violate any Applicable Law.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="flex">
                  <span className="text-blue-600 w-6">(c)</span>
                  <p className="text-gray-600 flex-1">
                    <span className="font-medium">Account Security:</span> You
                    are responsible for maintaining the confidentiality of your
                    account credentials and for all activities under your
                    account. You may not register for more than one Member
                    account. In order to access or use certain Products or
                    Services or other special offerings, you may be required to
                    agree to additional terms and conditions or policies; those
                    additional terms or policies are hereby incorporated into
                    these Terms. Where such terms or policies are inconsistent
                    with these Terms, the additional terms or policies will
                    control.
                  </p>
                </div>

                <div className="flex">
                  <span className="text-blue-600 w-6">(d)</span>
                  <p className="text-gray-600 flex-1">
                    <span className="font-medium">Non-Transferability:</span>{" "}
                    Member accounts and Subscriptions (as defined below) are not
                    transferable. You agree not to sell, transfer, or exchange
                    Member accounts or Subscriptions in any way or under any
                    circumstance. This expressly applies to any discounted,
                    subsidized, or Free Trials (as defined below) or
                    Subscriptions.
                  </p>
                </div>
              </div>
            </div>

            {/* Section 7.2 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">7.2</span>
                Children
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                Our Products and Services are intended for individuals at least
                18 years old. Limited exceptions may apply for users aged 13-17
                in the U.S., who may access certain offerings with verifiable
                parental or guardian consent. Please refer to materials specific
                to such offerings for more information.
              </p>
            </div>

            {/* Section 7.3 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">7.3</span>
                Subscriptions and Purchases
              </h3>

              <div className="ml-6 space-y-3">
                {["a", "b", "c", "d", "e", "f", "g", "h", "i"].map(
                  (letter, index) => (
                    <div key={index} className="flex">
                      <span className="text-blue-600 w-6">({letter})</span>
                      <p className="text-gray-600 flex-1">
                        {letter === "a" && (
                          <span className="font-medium">Free Trials:</span>
                        )}
                        {letter === "b" && (
                          <span className="font-medium">
                            Automatically Renewing Subscriptions:
                          </span>
                        )}
                        {letter === "c" && (
                          <span className="font-medium">
                            Subscription Cancellation:
                          </span>
                        )}
                        {letter === "d" && (
                          <span className="font-medium">
                            No Refunds on Subscriptions:
                          </span>
                        )}
                        {letter === "e" && (
                          <span className="font-medium">
                            Subscription Methods:
                          </span>
                        )}
                        {letter === "f" && (
                          <span className="font-medium">
                            Gift Subscriptions:
                          </span>
                        )}
                        {letter === "g" && (
                          <span className="font-medium">Purchases:</span>
                        )}
                        {letter === "h" && (
                          <span className="font-medium">Discounts:</span>
                        )}
                        {letter === "i" && (
                          <span className="font-medium">Payment Terms:</span>
                        )}{" "}
                        {letter === "a" &&
                          'You may have access to a free trial period for certain Products ("Free Trial"). You are only allowed one Free Trial regardless of your subscription method. Eligibility for Free Trials is at our discretion, and they automatically convert to a paid subscription unless canceled as per Section 8.'}
                        {letter === "b" &&
                          'Access to the Products or Services may require a fee-based subscription ("Subscription"). Subscriptions may be available via monthly or annual options ("Subscription Term"). Subscriptions are automatically renewing and charged periodically ("Subscription Fee") to your provided payment method ("Payment Method") until canceled.'}
                        {letter === "c" &&
                          "You may cancel your Subscription at any time before it renews to avoid billing for the next period. See Section 8 for more details."}
                        {letter === "d" &&
                          "Refunds are not provided for partial Subscription terms. Neuromonics will bill the periodic Subscription Fee to the Payment Method you provide during registration (or to a different payment method if you change your payment information)."}
                        {letter === "e" &&
                          "You may subscribe via our Website, Apps, a Benefit Sponsor (as defined in Section 7.5), or bundled subscription partners. Additional terms may apply based on your subscription method."}
                        {letter === "f" &&
                          "Gift Subscriptions are prepaid memberships. Once purchased, the Giftor will receive an Order confirmation and receipt. The Gift Subscription will be sent to the Recipient on the Giftor's specified date with a gifting code to redeem the Gift Subscription. Gifting codes can only be used once in the country for which they were purchased and cannot be redeemed for cash, resold, or combined with any other offers unless expressly stated otherwise."}
                        {letter === "g" &&
                          'Some Products or Services may be accessed by paying a non-recurring fee ("Purchase"). Purchases are final and non-refundable.'}
                        {letter === "h" &&
                          "Special discount pricing options may be available to qualified users. Additional terms may apply."}
                        {letter === "i" &&
                          "You are responsible for all applicable fees and charges, including taxes, unless otherwise stated in your Benefit Sponsor terms and conditions or otherwise agreed in writing. We reserve the right to change our fees and will notify you of any changes."}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>

            {/* Section 7.4 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">7.4</span>
                Payment Terms
              </h3>

              <div className="ml-6 space-y-3">
                {["a", "b", "c"].map((letter, index) => (
                  <div key={index} className="flex">
                    <span className="text-blue-600 w-6">({letter})</span>
                    <p className="text-gray-600 flex-1">
                      {letter === "a" && (
                        <span className="font-medium">Fees:</span>
                      )}
                      {letter === "b" && (
                        <span className="font-medium">Payment Method:</span>
                      )}
                      {letter === "c" && (
                        <span className="font-medium">
                          Updated Payment Information:
                        </span>
                      )}{" "}
                      {letter === "a" &&
                        "Your use of the Products and Services may require payment of fees. Prices may vary based on several factors, including your employer, healthcare provider, health plan, or other Benefit Sponsor's agreement with us."}
                      {letter === "b" &&
                        "You authorize Neuromonics or a third-party payment processor to charge all fees to your Payment Method."}
                      {letter === "c" &&
                        "In the course of your use of the Products or Services, Neuromonics and its third-party payment processor may receive and use updated credit card information from your credit card issuer to prevent your Subscription from being interrupted by an outdated or invalid card."}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {/* Section 7.5 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">7.5</span>
                Benefit Sponsors
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                Benefit Sponsors (e.g., employers, healthcare providers, health
                plans, universities, government entities, municipalities, or
                hospitals) may introduce the Products and Services to their
                employees, eligible dependents, and members. In some cases, our
                Benefit Sponsors may supplement these Terms with their own terms
                and conditions, which may include additional terms around
                subscription redemption, usage, or supplementary payment for
                access to Products and Services. In such events, the Benefit
                Sponsor terms and conditions will also apply to your use of the
                Products and Services. In the event of any conflict with such
                additional terms and these Terms, the additional terms will
                prevail.
              </p>
            </div>

            {/* Section 7.6 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">7.6</span>
                Changing Fees and Charges
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                We reserve the right to change our subscription fees, plans, or
                adjust pricing for our Products and Services or any components
                thereof in any manner and at any time as we may determine in our
                sole discretion. Except as otherwise expressly provided in these
                Terms, any price changes or changes to your subscription plan
                will take effect following notice to you.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                8
              </span>
              Canceling Membership
            </h2>

            {/* Section 8.1 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">8.1</span>
                Cancellation by Us
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                We may suspend or terminate your use of the Products and/or
                Services as a result of your fraud or breach of any obligation
                under these Terms. Such termination or suspension may be
                immediate and without notice. If we terminate your account, you
                may receive a pro-rata refund of fees that you prepaid for the
                Services. Your access to the Products or Services may be
                terminated if the relationship between Neuromonics (including
                SoundVida, Inc.) and your employer or provider terminates or
                expires, or if your relationship with your employer or provider
                ends.
              </p>
            </div>

            {/* Section 8.2 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">8.2</span>
                Cancellation by You
              </h3>

              <div className="ml-6 space-y-3">
                <div className="flex">
                  <span className="text-blue-600 w-6">(a)</span>
                  <p className="text-gray-600 flex-1">
                    <span className="font-medium">How to Cancel:</span> You may
                    cancel your Subscription at any time. Cancellation of a
                    monthly Subscription is effective at the end of the
                    applicable monthly period, and cancellation of an annual
                    Subscription is effective at the end of the applicable
                    annual period. Please make any such cancellation by visiting{" "}
                    <span className="text-blue-600 hover:underline cursor-pointer">
                      here
                    </span>{" "}
                    or emailing{" "}
                    <span className="text-blue-600">help@neuromonics.com</span>.
                  </p>
                </div>

                <div className="flex">
                  <span className="text-blue-600 w-6">(b)</span>
                  <p className="text-gray-600 flex-1">
                    <span className="font-medium">Third-Party Purchases:</span>{" "}
                    If you purchased a Subscription through the Apple iTunes
                    Store or our iPhone application, you may cancel your
                    Subscription by canceling automatic renewal of paid In-App
                    Subscriptions in your iTunes Account settings. If you
                    purchased through the Google Play Store, you may cancel
                    automatic renewals in account settings under Subscriptions
                    in the Google Play app.
                  </p>
                </div>
              </div>
            </div>

            {/* Section 8.3 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">8.3</span>
                Cancellation Fees
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                Some features of the Products and Services are subject to
                certain cancellation fees, as disclosed via the Products and
                Services. For example, such cancellation fees may be owed if you
                miss a scheduled therapy or psychiatry session and fail to
                provide the required advanced notice.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                9
              </span>
              Prohibited Use of the Products and Services
            </h2>

            <div className="ml-8">
              <p className="text-gray-600 mb-4">
                By using the Products and Services, you agree not to:
              </p>

              {/* Prohibited Actions List */}
              <div className="pl-6">
                <ul className="space-y-3">
                  {[
                    "Copy, store, reproduce, transmit, modify, alter, reverse-engineer, emulate, decompile, or disassemble the Products or Services in any way, or create derivative works of the Products or Services.",
                    "Record the Services, including any coaching, therapy, or psychiatry session.",
                    "Use the Products or Services for any unlawful purpose.",
                    "Transmit any harmful code or viruses.",
                    "Attempt to gain unauthorized access to our systems.",
                    "Interfere with the security or integrity of the Products and Services.",
                    "Violate any third-party right, including any intellectual property right.",
                    "Engage in offensive or harmful conduct, including bullying, harassing, or using hate speech.",
                    "Use the Products or Services for any commercial purposes without obtaining a written license from us.",
                    "Rent, lease, loan, make available to the public, sell, or distribute the Products or Services.",
                    "Use the Products or Services for any purposes related to scientific research without our express written consent.",
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <span className="text-gray-500 mr-3">•</span>
                      <span className="text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              {/* Warning Message */}
              <div className="mt-6 pt-4">
                <p className="text-gray-700 font-medium">
                  We reserve the right to terminate your access for violations
                  and may report breaches to law enforcement authorities.
                </p>
              </div>
            </div>
          </section>

          {/* 10 */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                10
              </span>
              Intellectual Property Rights
            </h2>

            {/* Section 10.1 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">10.1</span>
                Copyright
              </h3>

              <div className="ml-6 space-y-3">
                <div className="flex">
                  <span className="text-blue-600 w-6">(a)</span>
                  <p className="text-gray-600 flex-1">
                    <span className="font-medium">Ownership:</span> All
                    materials (including but not limited to software and content
                    whether downloaded or not) contained in the Products and
                    Services ("Materials") are owned by Neuromonics (including
                    SoundVida, Inc.) or our affiliates and/or third-party
                    licensors, where applicable.
                  </p>
                </div>

                <div className="flex">
                  <span className="text-blue-600 w-6">(b)</span>
                  <p className="text-gray-600 flex-1">
                    <span className="font-medium">Restrictions:</span> You may
                    not copy, distribute, modify, perform, transfer, create
                    derivative works from, sell, or otherwise exploit any
                    content, code, data, or materials in the Products or
                    Services without our prior written consent.
                  </p>
                </div>

                <div className="flex">
                  <span className="text-blue-600 w-6">(c)</span>
                  <p className="text-gray-600 flex-1">
                    <span className="font-medium">Termination:</span>{" "}
                    Unauthorized use immediately and automatically terminates
                    your right to use the Products and Services and may subject
                    you to legal liability.
                  </p>
                </div>
              </div>
            </div>

            {/* Section 10.2 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">10.2</span>
                Trademarks
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                Neuromonics<span className="align-super text-xs">®</span>, the
                Neuromonics logo, and all other Neuromonics product or service
                marks are trademarks of Neuromonics (including SoundVida, Inc.).
                All intellectual property, trademarks, logos, images, product,
                and company names displayed or referred to on or in the Products
                and Services are the property of their respective owners.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                11
              </span>
              Availability of Products and Services
            </h2>
            <div className="prose max-w-none text-gray-600">
              <p className="mb-4 ml-8">
                Although we aim to offer you the best service possible, we make
                no promise that the Products and Services will meet your
                requirements and cannot guarantee they will be fault-free. If a
                fault occurs, please report it to help@neuromonics.com, and we
                will review your concern.
              </p>
              <p className="mb-4 ml-8">
                Access to the Products and Services may be occasionally
                restricted to allow for repairs, maintenance, or the
                introduction of new services or products. We will restore the
                Products and Services as soon as we reasonably can.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                12
              </span>
              Assumption of Risk
            </h2>

            <div className="ml-8">
              <p className="text-gray-600 mb-4">
                By using the Products or Services, you acknowledge and accept
                that:
              </p>

              {/* Risk Acknowledgment List */}
              <div className="space-y-3">
                {[
                  {
                    title: "No Medical Advice:",
                    content:
                      "Neuromonics (including SoundVida, Inc.) is not a licensed medical care provider and does not provide medical treatment, advice, or diagnosis.",
                  },
                  {
                    title: "Consult Professionals:",
                    content:
                      "Always seek the advice of qualified healthcare providers with any questions you may have regarding a medical condition.",
                  },
                  {
                    title: "No Doctor-Patient Relationship:",
                    content:
                      "Your use of the Products and Services does not create a doctor-patient relationship between you and Neuromonics.",
                  },
                ].map((item, index) => (
                  <li key={index} className="flex items-start list-none">
                    <span className="text-blue-600 mr-3">•</span>
                    <span className="text-gray-700">
                      <span className="font-medium">{item.title}</span>{" "}
                      {item.content}
                    </span>
                  </li>
                ))}
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                13
              </span>
              Warranties and Disclaimers
            </h2>
            <div className="prose max-w-none text-gray-600">
              <p className="mb-4 ml-8">
                <span className="font-bold">As Is" Basis:</span> The Products
                and Services and all materials and content available through
                them are provided "as is" without warranties of any kind,
                whether express or implied.
              </p>
              <p className="mb-4 ml-8">
                <span className="font-bold">No Medical Advice:</span> Content
                provided is for informational purposes and is not medical
                advice. Only your physician or other healthcare provider can
                provide medical advice.
              </p>
              <p className="mb-4 ml-8">
                <span className="font-bold">Limitation of Liability:</span> To
                the fullest extent permitted by law, Neuromonics (including
                SoundVida, Inc.) is not liable for any indirect, incidental,
                special, consequential, or punitive damages arising out of your
                use of the Products and Services.
              </p>
            </div>
          </section>

          <section className="bg-white">
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                14
              </span>
              Dispute Resolution & Binding Arbitration
            </h2>

            <div className="pl-8">
              <p className="text-base font-medium mb-6">
                Please read this section carefully.
              </p>

              {/* Section 14.1 */}
              <div className="mb-6">
                <h3 className="text-base font-bold text-gray-900 mb-2">
                  14.1 Jury Trial Waiver
                </h3>
                <p className="text-gray-700">
                  You and Neuromonics (including SoundVida, Inc.) waive any
                  constitutional and statutory rights to go to court and have a
                  trial in front of a judge or a jury. Disputes will be resolved
                  by arbitration.
                </p>
              </div>

              {/* Section 14.2 */}
              <div className="mb-6">
                <h3 className="text-base font-bold text-gray-900 mb-2">
                  14.2 Class Action Waiver
                </h3>
                <p className="text-gray-700">
                  Disputes will be resolved on an individual basis; class
                  actions are not permitted. If this waiver is deemed invalid or
                  unenforceable, neither you nor Neuromonics are entitled to
                  arbitration; instead, all claims and disputes will be resolved
                  in court as set forth in Section 17.5 below.
                </p>
              </div>

              {/* Section 14.3 */}
              <div className="mb-6">
                <h3 className="text-base font-bold text-gray-900 mb-2">
                  14.3 Informal Dispute Resolution
                </h3>
                <p className="text-gray-700 mb-3">
                  Before initiating arbitration, you agree to try to resolve the
                  dispute informally by contacting us at help@neuromonics.com or
                  at:
                </p>
                <address className="text-gray-700 not-italic">
                  SoundVida, Inc.
                  <br />
                  ATTN: Legal
                  <br />
                  26565 West Agoura Road, Suite 200
                  <br />
                  Calabasas, California, 91302
                </address>
              </div>

              {/* Section 14.4 */}
              <div className="mb-6">
                <h3 className="text-base font-bold text-gray-900 mb-2">
                  14.4 Arbitration Agreement
                </h3>
                <p className="text-gray-700 mb-3">
                  All disputes arising out of, relating to, or in connection
                  with these Terms or your use of the Products and Services will
                  be resolved through binding arbitration on an individual
                  basis, except that you and Neuromonics retain the right to
                  bring an individual action in small claims court or seek
                  injunctive or other equitable relief in a court of competent
                  jurisdiction.
                </p>
                <p className="text-gray-700">
                  The arbitration will be conducted by the American Arbitration
                  Association ("AAA") under the AAA Consumer Arbitration Rules.
                </p>
              </div>

              {/* Section 14.5 */}
              <div className="mb-6">
                <h3 className="text-base font-bold text-gray-900 mb-2">
                  14.5 Opt-Out
                </h3>
                <p className="text-gray-700 mb-3">
                  You may opt-out of the arbitration agreement by notifying us
                  in writing no later than 30 days after first becoming subject
                  to this arbitration agreement. Your notice must include your
                  name, mailing address, email address used to set up your
                  Neuromonics account, and an unequivocal statement that you
                  want to opt-out of this arbitration agreement. Send your
                  opt-out notice to:
                </p>
                <address className="text-gray-700 not-italic">
                  SoundVida, Inc.
                  <br />
                  ATTN: Arbitration Opt-out
                  <br />
                  26565 West Agoura Road, Suite 200
                  <br />
                  Calabasas, California, 91302
                </address>
              </div>

              {/* Section 14.6 */}
              <div className="mb-6">
                <h3 className="text-base font-bold text-gray-900 mb-2">
                  14.6 Arbitration Agreement Survival
                </h3>
                <p className="text-gray-700">
                  This arbitration agreement will survive the termination of
                  your relationship with Neuromonics.
                </p>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                15
              </span>
              Electronic Communications Consent
            </h2>
            <div className="prose max-w-none text-gray-600">
              <p className="mb-4 ml-8">
                By providing us with your phone number and email address, you
                consent to receive electronic communications from us related to
                the Products and Services, including marketing and promotional
                messages. Standard text messaging charges applied by your cell
                phone carrier will apply to text messages we send. You may
                opt-out at any time by following the instructions provided in
                the communications.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                16
              </span>
              International Use
            </h2>
            <div className="prose max-w-none text-gray-600">
              <p className="mb-4 ml-8">
                If you are located outside of the United States, you may have
                access to certain Products and Services only. Access to the
                Products or Services or any features thereof from countries or
                territories where such access is illegal, banned, or restricted
                is prohibited.
              </p>
            </div>
          </section>

          {/* 17 */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                17
              </span>
              General Provisions
            </h2>

            {/* Section 17.1 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">17.1</span>
                Assignment
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                Neuromonics (including SoundVida, Inc.) may transfer its rights
                and obligations under these Terms to any company, firm, or
                person at any time if it does not materially affect your rights
                under it. You may not transfer your rights or obligations under
                these Terms to anyone else.
              </p>
            </div>

            {/* Section 17.2 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">17.2</span>
                Indemnity
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                To the fullest extent permitted by law, you agree to defend and
                indemnify Neuromonics (including SoundVida, Inc.), your
                employer, your health plan, or provider (as applicable), and
                each of their respective officers, directors, employees,
                consultants, affiliates, subsidiaries, and agents from and
                against any claim brought by a third party, and any related
                liability, damage, loss, and expense arising out of or connected
                with your unauthorized use of, or misuse of, the Products or
                Services.
              </p>
            </div>

            {/* Section 17.3 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">17.3</span>
                Limitation of Liability
              </h3>
              <div className="text-gray-600 ml-6 space-y-4">
                <p>
                  To the fullest extent permitted by law, the aggregate
                  liability of Neuromonics (including SoundVida, Inc.) to you
                  for all claims arising out of or relating to the use of or any
                  inability to use any portion of the Products and Services is
                  limited to the greater of:
                </p>
                <ul className="ml-6 space-y-2">
                  <li className="flex items-start">
                    <span className="text-blue-600 mr-2">•</span>
                    <span className="text-gray-600">
                      The amount you have paid to Neuromonics for access to and
                      use of the Products and Services in the 12 months prior to
                      the event or circumstance giving rise to the claim; or
                    </span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-blue-600 mr-2">•</span>
                    <span className="text-gray-600">$10,000.</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Section 17.4 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">17.4</span>
                No Waiver
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                If we delay exercising or fail to exercise or enforce any right
                available to us under these Terms, such delay or failure does
                not constitute a waiver of that right or any other rights under
                these Terms.
              </p>
            </div>

            {/* Section 17.5 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">17.5</span>
                Governing Law and Exclusive Venue
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                These Terms are governed by the laws of the State of California,
                other than its conflict-of-laws principles. Any disputes arising
                out of or relating to these Terms or the use of the Products and
                Services will be litigated exclusively in the United States
                District Court for the Central District of California. If that
                court lacks original jurisdiction, then all claims will be
                litigated exclusively in the Superior Court of California,
                County of Los Angeles. You consent to the personal jurisdiction
                of both courts.
              </p>
            </div>

            {/* Section 17.6 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">17.6</span>
                Severability
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                If any provision of these Terms is found unenforceable, then
                that provision will be severed from these Terms and not affect
                the validity and enforceability of any remaining provisions.
              </p>
            </div>

            {/* Section 17.7 */}
            <div className="ml-8 mt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <span className="text-blue-600 mr-2">17.7</span>
                Entire Agreement
              </h3>
              <p className="text-gray-600 mb-4 ml-6">
                These Terms and any additional terms or policies expressly
                referred to constitute the entire agreement between us and
                supersede all previous agreements between us relating to the
                Products and Services.
              </p>
            </div>
          </section>

          <section className="bg-white">
            <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
              <span className="bg-blue-100 text-blue-600 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                18
              </span>
              Contact Us
            </h2>

            <div className="mb-8 pl-8">
              <p className="text-gray-700 mb-4">
                If you have any questions or concerns about these Terms, please
                contact us at:
              </p>

              <div className="space-y-1">
                <div className="flex gap-2">
                  <span className="font-bold">Email:</span>
                  <span>help@neuromonics.com</span>
                </div>
                <div className="space-y-1">
                  <div className="font-bold">Address:</div>
                  <div>
                    SoundVida, Inc.
                    <br />
                    26565 West Agoura Road, Suite 200
                    <br />
                    Calabasas, California, 91302
                  </div>
                </div>
              </div>
            </div>

            {/* Divider */}
            <hr className="my-8 border-gray-200" />

            {/* Footer Agreement Text */}
            <p className="text-gray-700 font-bold mb-8">
              By using the Neuromonics Products and Services, you acknowledge
              that you have read, understood, and agree to be bound by these
              Terms and Conditions.
            </p>

            {/* Final Divider */}
            <hr className="border-gray-200" />
          </section>
        </div>
      </div>
      <Footer />
      {/* footer end */}
    </div>
  );
};

export default TermsCondition;
